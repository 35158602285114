<template>
  <div class="stats">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="stats__item">
            <div class="info">
              Вільних місць в Україні
              <div class="number">
                {{ serviceData.available_beds_ukraine.toLocaleString() || 0 }}
              </div>
              <b-button href="/find" variant="outline-primary">
                Переглянути усі
              </b-button>
            </div>
            <div class="icon">🇺🇦</div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="stats__item">
            <div class="info">
              Вільних місць за кордоном
              <div class="number">
                {{
                  serviceData.available_beds_intertational.toLocaleString() || 0
                }}
              </div>
              <b-button href="/find" variant="outline-primary">
                Переглянути усі
              </b-button>
            </div>
            <div class="icon">🌍</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    datasets() {
      return this.$store.getters["datasets"] || {};
    },
    serviceData() {
      return this.datasets.service_data || {};
    },
  },
};
</script>

<style lang="scss" scoped>
.stats {
  padding: 154px 0px 72px;
  background: #fff;
  position: relative;
  z-index: 10;
  &__item {
    background: var(--light-blue);
    padding: 35px;
    border-radius: 40px;
    display: flex;
    justify-content: space-between;
    color: #000;
    font-size: 20px;
    margin-bottom: 24px;
    @media (max-width: 575px) {
      padding: 20px;
      font-size: 16px;
      border-radius: 20px;
    }

    .number {
      font-size: 64px;
      @media (max-width: 575px) {
        font-size: 32px;
      }
    }
    .icon {
      height: 128px;
      width: 128px;
      min-width: 128px;
      border-radius: 50%;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 48px;
      @media (max-width: 575px) {
        height: 64px;
        width: 64px;
        min-width: 64px;
        font-size: 24px;
      }
    }
  }
}
</style>
