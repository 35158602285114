<template>
  <div>
    <b-overlay :show="isLoading" spinner-variant="primary" rounded="lg">
      <b-form
        v-if="use_case == 'forgot-password' && !reset_sent"
        @submit.prevent="resetPassword"
      >
        <h3 class="pb-2">Відновлення доступу</h3>
        <div class="input-group mb-2">
          <label class="d-block w-100">Email</label>
          <b-form-input v-model.trim="user.email"> </b-form-input>
        </div>
        <hr />
        <div class="form-group w-100">
          <b-button type="submit" block size="lg" variant="primary w-100"
            >Скинути пароль</b-button
          >
        </div>
      </b-form>

      <div v-if="reset_sent">
        <b-alert show variant="success">
          <h4 class="alert-heading">Пароль скинуто</h4>
          <p>
            На вашу пошту <strong>{{ user.email }}</strong> було відправлено
            лист з інструкціями по відновленню паролю
          </p>
        </b-alert>
      </div>
      <b-button
        block
        size="lg"
        variant="outline-primary  w-100 mt-2"
        @click="switchUsecase('login')"
      >
        <b-icon-arrow-left /> <span> Вхід</span></b-button
      >
    </b-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      use_case: "forgot-password",
      user: {
        email: "",
      },
      reset_sent: false,
    };
  },
  methods: {
    async resetPassword() {
      this.isLoading = true;
      const result = await this.$auth.forgotPassword(this.user.email);
      if (result.ok == true) {
        this.reset_sent = true;
      }
      this.isLoading = false;
    },
    switchUsecase(use_case) {
      this.$emit("switch", use_case);
    },
  },
};
</script>

<style lang="scss" scoped></style>
