import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

function firestorageMethods(app) {
  const storage = getStorage(app);
  return {
    upload: async (file, path) => {
      const storageRef = ref(storage, path);
      // Upload the file and metadata
      const uploadTask = uploadBytesResumable(storageRef, file);

      let url = await getDownloadURL(ref(storage, path))
        .then((url) => {
          return url;
        })
        .catch((error) => {
          return error;
        });
      return {
        url: url,
        task: uploadTask,
      };
    },
  };
}

export { firestorageMethods as default };
