<template>
  <div class="messanges-people mb-1 d-flex justify-content-between">
    <div class="messanges-people__image d-flex">
      {{ receiverName[0] }}
    </div>

    <div class="messanges-people__details">
      <div class="title-and-time d-flex flex-column justify-content-between">
        <h5 class="my-0 mb-1 d-flex align-items-center">
          {{ receiverName }}
          <small class="time ms-auto text-muted">
            {{ $moment(chat.last_message.createdAt).format("HH:mm") }}
          </small>
        </h5>
        <small
          v-if="chat.house && chat.house.location_title"
          class="house-into text-muted"
        >
          {{ chat.house.location_title }}
        </small>
        <h6 class="mt-1">
          <strong
            v-if="chat.last_message && chat.last_message.sender == user.id"
            >Ви:</strong
          >
          {{ lastMessage }}
        </h6>
      </div>
      <div v-if="unreadCount > 0" class="count">
        {{ unreadCount }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    chat: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },

  computed: {
    user() {
      return this.$store.getters["user"];
    },
    receiverData() {
      const receiver = this.chat.participants.filter(
        (el) => el.id !== this.$store.getters["user"].id
      );
      return receiver && receiver[0] ? receiver[0] : {};
    },
    receiverName() {
      let name = "Видалений користувач";
      if (this.receiverData.id) {
        name = this.receiverData.first_name;
        if (this.receiverData.last_name) {
          name += " " + this.receiverData.last_name[0] + ".";
        }
        if (!name || name.length < 1) {
          name = "Без імені";
        }
      }

      return name;
    },
    unreadCount() {
      let count = 0;
      if (this.chat.unread_counts) {
        const found = this.chat.unread_counts.find(
          (el) => el.participant === this.$store.getters["user"].id
        );
        if (found) {
          count = found.unread;
        }
      }
      return count;
    },
    lastMessage() {
      let message = "...";
      const last = this.chat.last_message;
      if (last && last.text) {
        message = last.text;
      }
      if (message.length > 20) message = message.slice(0, 20) + "...";

      return message;
    },
    contact() {
      let contact = {};
      if (this.chat.participants.length > 1) {
        contact = this.chat.participants.filter(
          (el) => el.id !== this.$store.getters["user"].id
        )[0];
      }

      return contact;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.messanges-people {
  border-radius: 8px;

  transition: 0.3s;
  padding: 12px;
  overflow: hidden;
  cursor: pointer;
  &__image {
    width: 48px;
    height: 48px;
    aspect-ratio: 1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    margin-right: 16px;
    background-color: var(--yellow);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1;
  }
  &.active {
    background: #f7f7f7 !important;
    border-color: #f7f7f7;
    @media screen and (max-width: 992px) {
      background: white;
    }
  }
  &__details {
    position: relative;
    width: calc(100% - 64px);
    .count {
      position: absolute;
      right: 0;
      top: 50%;
      width: 24px;
      transform: translateY(-50%);
      background: #000;
      border-radius: 50%;
      height: 24px;
      font-variation-settings: "wght" 700;
      font-size: 13px;
      color: #fff;
      align-items: center;
      justify-content: center;
      display: flex;
    }
    .title-and-time {
      h5 {
        font-family: "Fixel Text SH";
        font-style: normal;
        font-weight: 600;
        font-size: 14px !important;
        margin: 0;
        line-height: 1;
        color: #000;
        // overfolow with dots
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .house-into {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      padding-top: 4px;
    }
    .title-offer {
      position: relative;
      h5 {
        font-family: "Fixel Text SH";
        font-style: normal;
        font-variation-settings: "wght" 500;
        font-size: 14px;
        margin: 0;
        line-height: 1.2;
        color: #1e3173;
      }
      .count {
        position: absolute;
        right: 0;
        bottom: -12px;
        width: 24px;
        background: linear-gradient(102.5deg, #fff6da 0%, #ffdef2 100%);
        border-radius: 50%;
        height: 24px;
        margin-left: 8px;
        font-family: "Fixel Text SH";
        font-style: normal;
        font-variation-settings: "wght" 700;
        font-size: 12px;
        line-height: 1.2;
        color: #000000;
        align-items: center;
        justify-content: center;
        text-align: center;
        display: flex;
      }
    }
    .last-messange {
      h5 {
        font-family: "Fixel Text SH";
        font-style: normal;
        font-variation-settings: "wght" 400;
        font-size: 12px;
        line-height: 1.2;
        margin: 0;
        color: #818181;
      }
    }
  }
  &:hover {
    background: #f2f4f5;
    transition: 0.3s;
  }
}
</style>
