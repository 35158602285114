<template>
  <div>
    <div class="container">
      <div class="row justify-content-center py-5 my-4">
        <div class="col-12 col-sm-5">
          <b-card>
            <b-overlay :show="isLoading">
              <h3 class="mb-4">Встановлення нового паролю</h3>
              <div class="form-input mb-2">
                <label class="d-block w-100">Пароль</label>
                <b-form-input type="password" v-model.trim="password" />
              </div>
              <password
                v-model="password"
                :secureLength="6"
                :strength-meter-only="true"
              />
              <div class="form-input mb-2">
                <label class="d-block w-100">Повторіть пароль</label>
                <b-form-input type="password" v-model.trim="password_repeat" />
              </div>
              <div class="form-input pt-4">
                <b-button
                  class="w-100"
                  size="lg"
                  variant="primary"
                  @click="resetPassword"
                >
                  Встановити новий пароль
                </b-button>
              </div>
            </b-overlay>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Password from "vue-password-strength-meter";

export default {
  components: {
    Password,
  },
  data() {
    return {
      isLoading: false,
      password: "",
      password_repeat: "",
      code: "",
    };
  },
  methods: {
    async resetPassword() {
      this.isLoading = true;
      const result = await this.$auth.resetPassword(
        this.code,
        this.password,
        this.password_repeat
      );
      if (result.success == false) {
        this.$toast.error("Сталася помилка. Спробуйте ще раз");
      }
      if (result.jwt) {
        this.$toast.success("Пароль успішно змінено");
        await this.$auth.login(result.user.email, this.password);
        this.$router.push("/");
      }
      this.isLoading = false;
    },
  },
  mounted() {
    this.code = this.$route.query.code;
  },
};
</script>

<style lang="scss" scoped></style>
