import store from "../store";

function authService(api) {
  return {
    login: async (identifier, password) => {
      const result = await api
        .post("/user/login", {
          identifier: identifier,
          password: password,
        })
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return {
            success: false,
            error: error,
          };
        });

      if (result.jwt) {
        localStorage.setItem("token", result.jwt);
        store.commit("setUser", result.user);
        if (result.user.favorites) {
          store.commit("setFavourites", result.user.favorites);
        }
        return {
          success: true,
        };
      } else {
        return result;
      }
    },
    logOut() {
      localStorage.removeItem("token");
      store.commit("setUser", null);
      store.commit("setFavourites", []);
      setTimeout(() => {
        window.location.reload();
      }, 500);
      return {
        success: true,
      };
    },
    signUp: async (user) => {
      const result = await api
        .post("/user/signUp", user)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return {
            success: false,
            error: error,
          };
        });
      if (result.jwt) {
        localStorage.setItem("token", result.jwt);
        store.commit("setUser", result.user);
        if (result.user.favorites) {
          store.commit("setFavourites", result.user.favorites);
        }
        return {
          success: true,
        };
      } else {
        return result;
      }
    },
    forgotPassword: async (email) => {
      const result = await api
        .post("/auth/forgot-password", {
          email: email,
        })
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return {
            success: false,
            error: error,
          };
        });
      return result;
    },
    resetPassword: async (code, password, passwordConfirmation) => {
      const result = await api
        .post("/auth/reset-password", {
          code: code,
          password: password,
          passwordConfirmation: passwordConfirmation,
        })
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return {
            success: false,
            error: error,
          };
        });
      return result;
    },
  };
}

export { authService as default };
