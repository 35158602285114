<template>
  <div>
    <div class="step_one" v-if="showStepOne">
      <h5>1. Введіть Telegram ID</h5>
      <div class="form-input d-flex w-100 flex-column">
        <p>
          Для того аби отримати свій Telegram ID, відкрийте бота
          <a target="_blank" href="https://t.me/pkykhystok_notifications_bot">
            @pkykhystok_notifications_bot
          </a>
          в Telegram та натисніть на кнопку <strong>Start</strong> або напишіть
          йому будь-яке повідомлення.
        </p>
        <b-form-input
          type="text"
          id="telegram"
          placeholder="Введіть Telegram ID"
          v-model="chat_id"
          class="mb-3"
          :disabled="isLoading"
        />
        <b-button
          @click.prevent="sendTelegramCode"
          :disabled="!chat_id || isLoading"
          :class="{ 'ms-auto w-100': inModal }"
          variant="primary"
        >
          <b-spinner v-if="isLoading"></b-spinner>
          <span v-if="!isLoading">Отримати код підтвердження</span>
        </b-button>
      </div>
    </div>
    <div class="step_two" v-if="showStepTwo">
      <h5>2. Введіть код підтвердження</h5>
      <div class="form-input d-flex w-100 flex-column">
        <p>Введіть код підтвердження, який ви отримали від бота в Telegram.</p>
        <b-form-input
          type="text"
          id="confirm_code"
          placeholder="Введіть код підтвердження"
          v-model.number="confirm_code"
          class="mb-3"
          :disabled="isLoading"
        />
        <b-button
          @click.prevent="confirmTelegramCode"
          :disabled="!confirm_code || isLoading"
          :class="{ 'w-100 ms-auto': inModal }"
          variant="primary"
        >
          <b-spinner v-if="isLoading"></b-spinner>
          <span v-if="!isLoading">Підтвердити</span>
        </b-button>
      </div>
    </div>
    <div v-if="showSuccess">
      <b-alert show variant="primary">
        <h5>Telegram ID додано!</h5>
        <p class="mb-0">Ви отримуватимете сповіщення в Telegram.</p>
      </b-alert>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    inModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      usecase: "add",
      step: 1,
      chat_id: "",
      confirm_code: "",
    };
  },
  computed: {
    telegramAdded() {
      return this.$store.getters.telegramAdded;
    },
    showStepOne() {
      let show = false;
      if (this.step == 1 && !this.telegramAdded) {
        show = true;
      }
      if (this.step == 1 && this.telegramAdded && this.usecase == "update") {
        show = true;
      }

      return show;
    },
    showStepTwo() {
      let show = false;
      if (this.step == 2 && !this.telegramAdded) {
        show = true;
      }
      if (this.step == 2 && this.telegramAdded && this.usecase == "update") {
        show = true;
      }

      return show;
    },
    showSuccess() {
      return this.telegramAdded && this.usecase == "add";
    },
  },
  methods: {
    async sendTelegramCode() {
      this.isLoading = true;
      await this.$api
        .post("/user/sendTelegramCode/", {
          chatId: this.chat_id,
        })
        .then((response) => {
          if (response.data.success) {
            this.step = 2;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async confirmTelegramCode() {
      this.isLoading = true;
      await this.$api
        .post("/user/confirmTelegram", {
          chatId: this.chat_id,
          code: this.confirm_code,
        })
        .then((response) => {
          if (response.data.success) {
            this.$store.dispatch("fetchUserData");
            this.usecase = "add";
            this.$toast.success("Telegram ID успішно додано!");
            return;
          }
          this.$toast.error("Невірний код підтвердження");
        })
        .catch((error) => {
          this.$toast.error("Невірний код підтвердження");
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
