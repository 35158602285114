<template>
  <div class="subscriptions py-4 h-100">
    <div class="container h-100">
      <div class="row h-100" v-if="dataLoaded && !subscriptions.length">
        <div class="col-12 h-100">
          <no-data image="bell.png">
            <template #text>Підписок на оновлення немає </template>
            <template #actions>
              <b-button href="/find" variant="primary">
                <b-icon-search class="me-3" />
                Шукати оголошення
              </b-button>
            </template>
          </no-data>
        </div>
      </div>
      <div class="row" v-if="dataLoaded && subscriptions.length">
        <div class="col-12">
          <h1>Підписки на оновлення</h1>
        </div>
        <div class="col-12 col-md-8">
          <div
            class="subscriptions__item mb-3"
            v-for="s in subscriptions"
            :key="s.id"
          >
            <b-card>
              <h4 class="mt-2">
                <b-icon-bell class="me-2" />
                {{ s.location_title }}
                <span class="text-muted">+{{ s.radius }} км</span>
              </h4>

              <div class="tags pt-2">
                <div class="tag" v-for="type in s.persons_type" :key="type.id">
                  <b-icon-check />
                  {{ getLabel(type.id, "persons_type") }}
                </div>
                <div class="tag" v-for="period in s.period" :key="period.id">
                  <b-icon-check />
                  {{ getLabel(period.id, "periods") }}
                </div>
                <div
                  class="tag"
                  v-for="accomodation in s.accomodation_types"
                  :key="accomodation.id"
                >
                  <b-icon-check />
                  {{ getLabel(accomodation.id, "accomodation_types") }}
                </div>
              </div>
              <div
                class="controls border-top mt-3 pt-3 d-flex flex-column flex-sm-row align-items-center"
              >
                <div class="left mb-4 mb-sm-0">
                  <small class="text-muted me-4 pe-4">
                    Створено
                    {{ $moment(s.createdAt).format("DD.MM.YYYY HH:mm") }}
                  </small>
                  <span>
                    <icon-svg :size="12" variant="persons" class="me-1" />
                    {{ s.persons }}
                  </span>
                </div>
                <b-button
                  variant="outline-danger px-3 d-block d-sm-inline-block ms-sm-auto"
                  size="sm"
                  :disabled="removeLoading && deleteBtnLoading(s.id)"
                  @click="removeSubscription(s.id)"
                >
                  <b-spinner
                    v-if="removeLoading && deleteBtnLoading(s.id)"
                    small
                  />
                  <span v-else> Видалити </span>
                </b-button>
              </div>
            </b-card>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <b-alert show variant="primary">
            <h5 class="d-flex align-items-center">
              <img
                src="@/assets/img/telegram_icon.svg"
                class="mb-2 me-2"
                height="24"
                alt=""
              />
              <div class="mb-2">Сповіщення в Telegram</div>
            </h5>
            <small class="d-block mb-3">
              Отримуйте сповіщення про нові оголошення в Telegram. Для цього
              приєднайте свій Telegram ID.
            </small>
            <b-button
              v-if="!telegramAdded"
              @click="addTelegram"
              variant="primary"
              class="w-100"
            >
              <b-icon-telegram class="me-2" />
              Приєднати Telegram
            </b-button>
            <div class="success" v-if="telegramAdded">
              <b-icon-check-circle-fill class="me-2" />
              <strong> Telegram підключено </strong>
            </div>
          </b-alert>
          <b-card>
            <circle-icon :image="'bell.png'" color="#F9F9F9" />
            <p>
              Тут ви можете переглянути всі ваші підписки на оновлення
              оголошень. Як тільки на сайті з'явиться нове оголошення, яке
              відповідає вашим критеріям, ви одразу отримаєте сповіщення.
            </p>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import circleIcon from "@/components/circle-icon.vue";
export default {
  components: {
    circleIcon,
  },
  data() {
    return {
      subscriptions: [],
      dataLoaded: false,
      isLoading: false,
      removeIds: [],
      removeLoading: false,
    };
  },
  computed: {
    datasets() {
      return this.$store.state.datasets;
    },
    telegramAdded() {
      return this.$store.getters.telegramAdded;
    },
  },
  methods: {
    getLabel(value, type) {
      let label = value;

      let items = this.datasets[type] || [];
      console.log(items);
      if (items.length) {
        let found = items.find((item) => Number(item.id) === Number(value));

        if (found) {
          label = found.label;
        }
      }

      return label;
    },
    addTelegram() {
      const event = new CustomEvent("openTelegramAddModal");
      window.dispatchEvent(event);
    },
    async removeSubscription(id) {
      this.removeLoading = true;
      this.removeIds.push(id);
      await this.$api
        .post(`/subscriptions/delete/${id}`)
        .then((response) => {
          if (response.data.success) {
            this.$toast.success("Підписка видалена");
            this.getSubscriptions();
          } else {
            this.$toast.error("Помилка при видаленні підписки");
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.removeLoading = false;
          this.removeIds = this.removeIds.filter((i) => i !== id);
        });
    },
    async getSubscriptions() {
      this.isLoading = true;
      await this.$api
        .get("/subscriptions/get/")
        .then((response) => {
          this.subscriptions = response.data.data;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.dataLoaded = true;
        });
    },
    deleteBtnLoading(id) {
      return this.removeIds.includes(id);
    },
  },
  mounted() {
    this.getSubscriptions();
  },
};
</script>

<style lang="scss" scoped>
.tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  .tag {
    border: 1px solid var(--primary-blue);
    padding: 5px 8px;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 16px;
    color: var(--primary-blue);
  }
}
</style>
