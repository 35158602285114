<template>
  <div>
    <div class="container pt-4">
      <h1 class="mb-2">
        Надіслати дані про житло до ОМС (органу місцевого самоврядування)
      </h1>
      <p>Текст - пояснення</p>
      <b-alert variant="success" class="mb-3" show>
        <b-icon-lock-fill />
        Всі дані будуть доступні лише органами місцевого самоврядування та
        <strong> не відобаражтимуться на сайті</strong>
      </b-alert>
      <b-card>
        <b-overlay
          :show="isLoading"
          variant="white"
          size="xl"
          spinner-variant="success"
        >
          <div class="row">
            <div class="col-12 col-lg-6">
              <cvkAddressInput
                :house="house"
                :key="house.uid"
                @updateAddress="updateAddress"
              />
              <div class="form-input pt-3">
                <h6>Контактний телефон</h6>
                <vue-tel-input
                  v-model="house_payload.userPhone"
                  disabled
                  :mode="'international'"
                  :autoDefaultCountry="false"
                  :defaultCountry="'UA'"
                  @validate="validatePhone"
                ></vue-tel-input>
              </div>
              <div
                :class="{ error: validate && !valid.persons }"
                class="form-input pt-3"
              >
                <h6>
                  Кількість осіб, яку ви готові розмістити
                  <span class="required">*</span>
                </h6>
                <v-select
                  v-model="house_payload.countPersons"
                  :options="numberOptions"
                ></v-select>
              </div>
              <div class="form-input pt-3">
                <h6>Тип розміщення</h6>
                <div
                  :class="{ error: validate && !valid.accomodation_type }"
                  class="custom-checkbox"
                  v-for="p in accomodationOptions"
                  :key="p.value"
                >
                  <label :for="'accomodation-types-' + p.value">
                    <input
                      :id="'accomodation-types-' + p.value"
                      type="checkbox"
                      v-model="house_payload.placementTermCode"
                      :value="p.value"
                      name="accomodation-types"
                    />
                    <div class="box">
                      <b-icon-check />
                    </div>
                    <span> {{ p.text }} </span>
                  </label>
                </div>
              </div>
              <div class="form-input pt-3">
                <h6>Кого ви готові розмістити</h6>
                <div
                  :class="{ error: validate && !valid.persons_type }"
                  class="custom-checkbox"
                  v-for="p in personsOptions"
                  :key="p.value"
                >
                  <label :for="'person-types-' + p.value">
                    <input
                      :id="'person-types-' + p.value"
                      type="checkbox"
                      v-model="house.persons_type"
                      :value="p.value"
                      name="persons-type"
                    />
                    <div class="box">
                      <b-icon-check />
                    </div>
                    <span> {{ p.text }} </span>
                  </label>
                </div>
              </div>
              <div class="form-input pt-3">
                <h6>На який період готові надати прихисток</h6>
                <div
                  :class="{ error: validate && !valid.period }"
                  class="custom-checkbox"
                  v-for="p in periodOptions"
                  :key="p.value"
                >
                  <label :for="'period-types-' + p.value">
                    <input
                      :id="'period-types-' + p.value"
                      type="checkbox"
                      v-model="house_payload.placementTermCode"
                      :value="p.value"
                      name="period-types"
                    />
                    <div class="box">
                      <b-icon-check />
                    </div>
                    <span> {{ p.text }} </span>
                  </label>
                </div>
              </div>
              <div
                :class="{ error: validate && !valid.notes }"
                class="form-input pt-3"
              >
                <h6 class="has-description">
                  Розкажіть детальніше про помешкання
                  <span class="required">*</span>
                </h6>
                <small>
                  Будинок? Квартира? Приміщення? Коли готові надати житло? На
                  який термін? Які умови в помешканні? (мінімум 72 символи)
                </small>
                <textarea
                  class="w-100 py-2 px-2 notranslate"
                  v-model="house_payload.notes"
                  rows="4"
                  placeholder="Наприклад, приватний будинок, є газ, вода, опалення, 3 ліжка та 1 розкладний диван. Можу прийняти вже зараз на 3-4 дні"
                >
                </textarea>
              </div>
            </div>
            <!-- /col -->
            <div class="col-12 col-lg-6">
              <pre>
              {{ address }}
              </pre>
              <pre>
              {{ house_payload }}
              </pre>
            </div>
          </div>
        </b-overlay>
      </b-card>
    </div>
  </div>
</template>

<script>
import { person_types, accomodation_types, periods } from "@/constants/";
import { mapGetters } from "vuex";
import cvkAddressInput from "./cvk-address-input.vue";
export default {
  components: {
    cvkAddressInput,
  },
  data() {
    return {
      accomodation_types: accomodation_types,
      periods: periods,
      house: {},
      //CVK address data

      address: {},

      validate: false,
      phoneValid: false,
      house_payload: {
        buildCode: "17509717",
        flatNo: 90,
        countPersons: 10, //+
        personTypeCodes: [1100, 1300], //+
        notes: "Some notes1", //+
        placementTermCode: [100], //+
        userPhone: null, //+
        userEmail: null,
        isAvailable: true,
        createdAt: "2022-10-11 13:07:59",
        updatedAt: "2022-11-11 13:09:40",
      },
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
      isPhoneVerified: "isPhoneVerified",
    }),
    accomodationOptions() {
      let options = accomodation_types;
      return options;
    },
    periodOptions() {
      let options = periods;
      return options;
    },
    numberOptions() {
      let numbers = [];
      let i = 1;
      while (i < 501) {
        numbers.push(i);
        i++;
      }

      return numbers;
    },
    regionOptions() {
      let options = [];
      options = this.regions.map((el) => {
        return {
          label: el.name_ua,
          value: el.ato_id,
        };
      });
      return options;
    },
    personsOptions() {
      let options = person_types;
      return options;
    },
  },
  methods: {
    updateAddress(value) {
      this.address = value;
    },
    validatePhone(value) {
      if (value.valid) {
        this.house["formattedPhone"] = value.number;
        this.phoneValid = true;
      } else {
        this.phoneValid = false;
      }
    },

    async fetchHouse() {
      this.isLoading = true;
      let uid = this.$route.params.uid;

      let result = await this.$db.getDocument("houses", uid);
      if (result.created_by) {
        result["updated"] = result.updated_at
          ? result.updated_at.seconds
          : result.created_at.seconds;

        this.house = result;

        //SET REGION
        // let region_id = this.regionOptions.find(
        //   (el) => el.label == this.house.region
        // );
        // if (region_id) {
        //   this.address.region_id = region_id.value;
        // }

        //SET PHONE
        this.house_payload.userPhone = this.house.phone;

        //SET EMAIL
        this.house_payload.userEmail = this.user.email;

        //SET PERSON TYPES
        this.house_payload.personTypeCodes = this.house.persons_type;

        //SET COUNT
        this.house_payload.countPersons = this.house.persons;

        //SET TERM
        this.house_payload.placementTermCode = this.house.period;

        //SET NOTES
        this.house_payload.notes = this.house.notes;

        //Set created At
        this.house_payload.createdAt = this.$moment(
          this.house.created_at.seconds * 1000
        ).format("YYYY-MM-DD, hh:mm:ss");

        //Set updated_at
        this.house_payload.updatedAt = this.$moment(
          this.house.updated * 1000
        ).format("YYYY-MM-DD, hh:mm:ss");

        //Set active state
        this.house_payload.isAvailable = !this.house.is_deactivated;
      }
      this.isLoading = false;
    },
  },
  mounted() {
    if (this.$route.params.uid) {
      this.fetchHouse();
    } else {
      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss" scoped>
.steps {
  margin: 20px;
  .item {
    position: relative;
    text-align: left;
    padding-bottom: 8px;
    padding-left: 4px;
    color: #666;
    &.success {
      color: green;
    }
    svg {
      position: absolute;
      left: 0;
      top: 2px;
      transform: translateX(-110%);
      width: 20px;
      height: 20px;
    }
  }
}
.success {
  color: green !important;
}
.error {
  color: #f00 !important;
}
h1 {
  line-height: 1 !important;
  margin-bottom: 30px;
}
.form-input {
  padding-bottom: 12px;

  h6 {
    font-weight: 700;
    margin-bottom: 4px;
    &:not(.has-description) {
      margin-bottom: 8px;
    }
    font-size: 18px;
    &.error {
      color: #f00;
    }
    &.success {
      color: green;
    }
  }
  small {
    color: #424242;
    margin-bottom: 12px;
    display: block;
    font-size: 13px;
  }
  span.required {
    color: #f00;
    font-size: 13px;
  }
}
</style>
