<template>
  <div class="b-toggle-menu" ref="menuRef">
    <div class="b-toggle-menu__toggle" @click.stop="toggle">
      <slot name="toggle">...</slot>
    </div>
    <div class="b-toggle-menu__list" :class="{ show: show }">
      <slot name="list"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ToggleMenu",
  data() {
    return {
      show: false,
      menuRef: null,
    };
  },
  methods: {
    toggle() {
      this.show = !this.show;
    },
    handleClickOutside(event) {
      if (this.menuRef && !this.menuRef.contains(event.target)) {
        this.show = false;
      }
    },
  },
  mounted() {
    this.menuRef = this.$refs.menuRef;
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style lang="scss">
.b-toggle-menu {
  position: relative;
  &__toggle {
    cursor: pointer;
    svg {
      width: 16px !important;
      height: 16px !important;
    }
  }
  &__list {
    background: #fff;
    border: 1px solid #f7f7f7;
    right: 0;
    top: 100%;
    position: absolute;
    border-radius: 8px;
    padding: 6px 0px;
    min-width: 140px;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
    z-index: 20;
    &.show {
      visibility: visible;
      opacity: 1;
    }
    a {
      display: block;
      padding: 9px 13px;
      text-decoration: none;
      font-size: 14px;
      white-space: nowrap;
      display: flex;
      align-items: center;
      img {
        margin-right: 7px;
      }
      &:hover {
        background: #f7f7f7;
      }
    }
    .separator {
      height: 1px;
      background: #f7f7f7;
      margin: 6px 0px;
    }
  }
}
</style>
