<template>
  <div class="messages-sidebar d-flex flex-column">
    <div class="messages-sidebar__search">
      <h2 class="messages-sidebar__title mb-3">Усі повідомлення</h2>
    </div>
    <div class="messages-sidebar__people d-flex flex-column">
      <b-overlay :show="isLoading && !chats.length">
        <div class="scroll pr-3">
          <div v-for="c in filteredChats" :key="c.id" class="pb-1 mb-2 item">
            <myMessagesChatContact
              :chat="c"
              :class="{ active: selected.id == c.id }"
              @click.native="selectChat(c)"
            />
          </div>
        </div>
        <div
          v-if="dataLoaded && !chats.length"
          class="no-contacts mb-auto mt-4 text-center"
        >
          <div class="text-muted pt-3">
            <h6>Немає чатів</h6>
          </div>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import myMessagesChatContact from "./chat-contact.vue";
export default {
  components: {
    myMessagesChatContact,
  },
  data() {
    return {
      isLoading: false,
      selected: {},
      active_tab: "",
      contactsOpened: false,
      dataLoaded: false,
      chats: [],
      q: "",
    };
  },
  computed: {
    filteredChats() {
      const sortedChats = [...this.chats].sort((a, b) => {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      });

      if (this.q) {
        return this.chats.filter((c) => {
          const contact = c.participants.find(
            (p) => p.id !== this.$store.getters.user.id
          );
          if (contact) {
            return (
              contact.first_name.toLowerCase().includes(this.q.toLowerCase()) ||
              contact.last_name.toLowerCase().includes(this.q.toLowerCase())
            );
          } else {
            return false;
          }
        });
      } else {
        return sortedChats;
      }
    },
  },

  mounted() {
    this.fetchChatList();
    setInterval(() => this.fetchChatList(), 5000);
  },
  methods: {
    selectChat(chat) {
      this.selected = chat;
      this.$emit("select", chat);
    },
    async fetchChatList() {
      this.isLoading = true;
      await this.$api
        .post(`/chats/getUserChats`)
        .then((response) => {
          // console.log(response.data);
          this.chats = response.data.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
          this.dataLoaded = true;
        });
      if (this.$route.query.chat) {
        const chat = this.chats.find(
          (c) => c.id === Number(this.$route.query.chat)
        );
        if (chat) {
          this.selectChat(chat);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.messages-sidebar {
  padding: 18px;
  height: 100%;
  background: #fff;
  border-radius: 16px;
  &__title {
    font-size: 20px;
    font-weight: 600;
    color: #000;
  }
  &__people {
    padding: 0px 4px 0px 0px;
    max-height: calc(100% - 120px);
    position: relative;
    height: 100%;
    .item {
    }
    .b-overlay-wrap {
      height: 100%;
    }
    .scroll {
      position: absolute;
      bottom: 0;
      top: 0;
      overflow-y: scroll;
      left: 0;
      right: 0;
    }
  }
}
</style>
