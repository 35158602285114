<template>
  <div class="partners text-center text-md-start">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="mb-4">Партнери</h2>
          <div
            class="d-flex py-3 align-items-center justify-content-center justify-content-md-between flex-wrap"
          >
            <a
              v-for="(partner, i) in partners"
              :key="i"
              :href="partner.link"
              target="_blank"
              class="partners__item inline-block"
            >
              <img
                style="height: 48px"
                :src="require(`@/assets/img/${partner.image}`)"
                :alt="partner.name"
              />
            </a>
          </div>
        </div>
        <div class="col-12 color-black text-center py-5 border-top">
          Технічне рішення "Прихисток" розробляється за підтримки
          <strong
            >Акселераційної програми Act to Support проєкту «Фенікс»</strong
          >, що виконується <strong>Фондом Східна Європа</strong> коштом
          <strong>Європейського Союзу</strong>
          <div
            class="pt-4 d-flex flex-column flex-md-row flex-wrap align-items-center justify-content-md-center"
          >
            <img
              height="48"
              class="mx-3 my-2"
              :src="require(`@/assets/img/forward_together.png`)"
              alt=""
            />
            <img
              height="48"
              class="mx-3 my-2"
              :src="require(`@/assets/img/EEF_RGB_UA_trnsp.png`)"
              alt=""
            />
            <img
              height="48"
              class="mx-3 my-2"
              :src="
                require(`@/assets/img/EU_Phoenix Program_logo_crvFN-01.png`)
              "
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      partners: [
        {
          name: "Міністерство реінтеграції тимчасово окупованих територій України",
          link: "https://minre.gov.ua/",
          image: "min_reintegration.png",
        },
        {
          name: "Фонд Східна Європа",
          link: "https://eef.org.ua/",
          image: "EEF_RGB_UA_trnsp.png",
        },
        {
          name: "UNHCR",
          link: "https://www.unhcr.org/",
          image: "unhcr.png",
        },
        {
          name: "MTI Groups",
          link: "https://www.mti.ua/mti-groups/",
          image: "mti.gif",
        },
        {
          name: "Fora",
          link: "https://shop.fora.ua/",
          image: "fora.svg",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.partners {
  background: #fff;
  h2 {
    font-size: 32px;
    color: black;
  }
  &__item {
    padding: 20px;
    transition: 0.3s;
    img {
      // filter: grayscale(1);
    }
    @media (max-width: 575px) {
      min-width: 100%;
    }
    @media (min-width: 768px) {
      &:first-child {
        padding-left: 0;
      }
    }
    &:hover {
      opacity: 0.7;
      transition: 0.3s;
      img {
        filter: grayscale(0);
      }
    }
    svg {
      height: 64px;
      width: auto;
    }
  }
}
</style>
