<template>
  <div
    class="checkbox-container d-flex flex-row-reverse justify-content-center align-items-center"
  >
    <input v-model="checked" type="checkbox" id="checkbox" />
    <label for="checkbox">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: "CustomCheckbox",
  props: {
    label: String,
    value: Boolean,
  },
  data() {
    return {
      checked: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.checked = newValue;
    },
    checked(newValue) {
      this.$emit("input", newValue);
    },
  },
};
</script>

<style scoped lang="scss">
input[type="checkbox"] {
  position: relative;
  display: block;
  margin: 0 auto;
  cursor: pointer;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: -8px;
    left: -23px;
    height: 28px;
    width: 46px;
    background-color: #dcdcdc;
    border-radius: 14px;
    transition: background-color 0.2s;
    cursor: pointer;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    transition: transform 0.2s, -webkit-transform 0.2s;
    top: -6px;
    left: -21px;
    border-radius: 12px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    height: 24px;
    width: 24px;
    cursor: pointer;
  }

  &:checked {
    &:before {
      background-color: #004bc1;
    }
    &:after {
      transform: translateX(18px);
      -webkit-transform: translateX(18px);
    }
  }
}
label {
  margin-right: 30px;
}
</style>
