<template>
  <div class="mt-4">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="ps-3 pe-3">
            <h1>Інформацію для виїзду за кордону</h1>
            <h4 class="pt-3">
              <b>Що робити, якщо закінчується строк дійсності паспорта</b>
            </h4>
            <p>
              З 24 лютого 2022 року, відповідно до Закону України "Про правовий
              режим воєнного стану", в Україні введено
              <b>режим воєнного стану!</b>
            </p>
            <p>
              З 1 березня 2022 року строк дії паспортів громадян України для
              виїзду за кордон може
              <b
                >продовжуватись на 5 років, процедура продовження безоплатна!
              </b>
            </p>
            <p>
              Прийнято
              <a
                target="_blank"
                href="https://www.kmu.gov.ua/npas/deyaki-pitannya-vnesennya-informaciyi-do-pasporta-gromadyanina-ukrayini-dlya-viyizdu-170"
                >постанову Кабінетом Міністрів України 28 лютого 2022 року № 170
                "Деякі питання внесення інформації до паспорта громадянина
                України для виїзду за кордон"</a
              >
              у зв’язку з військовою агресією Російської Федерації та введенням
              воєнного стану на усій території України.
            </p>
            <p>
              Крім того, до паспортів батьків або законних представників
              здійснюється вклеювання фотокартки дітей із зазначенням їх
              персональних даних. Ця інформація посвідчує їхню особу та
              підтверджує громадянство України.
            </p>
            <p>
              Продовження строку дії паспортів, вклеювання фотокарток дитини до
              паспортів батьків або законних представників, здійснюють
              територіальні органи/територіальні підрозділи ДМС, а також
              посольства та консульські установи України.
            </p>
            <p>
              Так, інформація про дитину, внесена до паспорта для виїзду за
              кордон законного представника, посвідчує особу та підтверджує
              громадянство України такої дитини.
            </p>
            <h4 class="pt-3">
              <b>Виїзд неповнолітньої дитини за кордон</b>
            </h4>
            <p>
              інформація про дитину, внесена до паспорта для виїзду за кордон
              законного представника, посвідчує особу та підтверджує
              громадянство України такої дитини.
            </p>
            <p>
              ◆ Внесення до паспорта для виїзду за кордон законного представника
              інформації про дитину здійснюється на підставі
              <b> заяви законного представника</b>, до паспорта якого вноситься
              така інформація. Документи для внесення до паспорта для виїзду за
              кордон законного представника інформації про дитину подаються ним
              <u
                >до територіального органу/територіального підрозділу ДМС за
                місцем звернення.</u
              >
            </p>
            <p>
              У разі постійного проживання або тимчасового перебування особи за
              кордоном документи подаються до посольства або консульської
              установи України.
            </p>
            <p>
              <i
                >Законний представник для внесення до паспорта для виїзду за
                кордон інформації про дитину подає такі документи:
              </i>
            </p>
            <div class="text-start ps-5">
              <ol>
                <li>паспорт для виїзду за кордон;</li>
                <li>
                  заяву про внесення до паспорта для виїзду за кордон інформації
                  про дитину за формою згідно з додатком 1;
                </li>
                <li>
                  свідоцтво про народження або документ, що підтверджує факт
                  народження, виданий компетентними органами іноземної держави.
                  У разі відсутності свідоцтва про народження законний
                  представник може подати витяг з Державного реєстру актів
                  цивільного стану громадян про державну реєстрацію народження;
                </li>
                <li>
                  дві фотокартки дитини розміром 3,5 х 4,5 сантиметра,
                  зображення на яких відповідає вимогам, що пред’являються до
                  фотокарток, що подаються для оформлення або обміну документів,
                  що посвідчують особу, підтверджують громадянство України чи
                  спеціальний статус особи, та фотографічного зображення на них,
                  затвердженим наказом МВС від 18 жовтня 2019 р. № 875;
                </li>
                <li>
                  документ, що підтверджує повноваження особи як законного
                  представника.
                </li>
              </ol>
            </div>
            <p class="mt-4">
              Внесення інформації про дитину здійснюється
              <b> в день звернення законного представника безоплатно.</b>
            </p>
            <p>
              Видані компетентними органами іноземної держави документи, що
              подаються для внесення до паспорта для виїзду за кордон інформації
              про дитину, засвідчуються в установленому законодавством порядку,
              якщо інше не передбачено міжнародними договорами України. Такі
              документи подаються з перекладом на українську мову, засвідченим
              нотаріально.
            </p>
            <p>
              ◆
              <u> У разі закінчення строку дії паспорта для виїзду за кордон</u>
              такий строк може бути продовжено до п’яти років на підставі
              <b> заяви особи або її законного представника.</b>
            </p>
            <p>
              Заява про внесення до паспорта для виїзду за кордон інформації про
              продовження строку його дії за формою згідно додатком 3 подається
              разом з паспортом для виїзду за кордон до територіального
              органу/територіального підрозділу ДМС, посольства або консульської
              установи України, за місцем звернення.
            </p>
            <p>
              Внесення до паспорта для виїзду за кордон інформації про
              продовження строку його дії здійснюється
              <b>безоплатно в день звернення особи</b> , на чиє ім’я оформлено
              паспорт, чи її законного представника.
            </p>
            <h4 class="pt-3">
              <b>
                Кому з чоловіків дозволено перетинати кордон України під час
                воєнного стану
              </b>
            </h4>
            <p>
              На період дії правового режиму воєнного стану чоловікам –
              громадянам України, віком від 18 до 60 років, обмежено виїзд за
              межі України.
            </p>
            <p>Зокрема тих, які мають:</p>
            <ol>
              <li>
                посвідчення про відстрочку від призову та повідомлення про
                зарахування на спеціальний військовий облік, або
              </li>
              <li>висновок військово-лікарської комісії про непридатність</li>
            </ol>

            <p>Крім того обмеження не стосуються осіб:</p>
            <ol>
              <li>
                на утриманні яких перебувають троє і більше дітей віком до 18
                років, або
              </li>
              <li>
                які самостійно виховують дитину (дітей) віком до 18 років, або
              </li>
              <li>на утриманні яких є дитина з інвалідністю.</li>
            </ol>
            <p>А також тих, хто є:</p>
            <ol>
              <li>усиновителем</li>
              <li>опікуном, або</li>
              <li>
                чиї близькі родичі загинули або зникли безвісти під час
                проведення антитерористичної операції.
              </li>
            </ol>
            <h4 class="pt-3">
              <b
                >Чи потрібно підтвердження адреси куди прямуєте та де плануєте
                зупинитись</b
              >
            </h4>
            <p>Наразі підтвердження адреси не потрібно.</p>
            <h4 class="pt-3">
              <b
                >Чи можуть неповнолітні діти перетинати кордон України без
                супроводу батьків</b
              >
            </h4>
            <p>
              Ні. Тільки у супроводі законного опікуна або нотаріально
              засвідчено довіреної особи.
            </p>
            <h4 class="pt-3">
              <b>Що потрібно мати при собі перетинаючи Український кордон? </b>
            </h4>
            <ol>
              <li>
                будь-який документ, що посвідчує особу (внутрішній паспорт /
                ID-карту або закордонний паспорт);
              </li>
              <li>свідоцтво про народження дитини (якщо прямують з нею);</li>
              <li>медичні документи (бажано, але не обов'язково);</li>
              <li>
                дозвіл іншого з батьків на виїзд з дитиною віком до 16 років
                (вимога українського МЗС, на деяких кордонах є винятки до цього
                правила).
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
