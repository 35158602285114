<template>
  <div
    class="chat__messange flex-column"
    :class="{ your: idUser == messageSender }"
  >
    <div class="d-inline-flex w-100">
      <div
        class="message-content w-100 d-flex flex-column"
        :class="{ 'text-right': idUser == messageSender }"
      >
        <div class="text" v-html="formattedText"></div>
        <div
          class="service-info d-flex align-items-center"
          :class="{ 'justify-content-end': idUser == messageSender }"
        >
          <div v-if="message.local_status == 'error'" class="resend mr-2">
            <a href="#" @click.prevent="resendMessage"> Повторити </a>
          </div>
          <div v-if="message.local_status === 'sending'" class="mr-2">
            Надсилання...
          </div>
          {{ formatDate(message.createdAt) }}
          <div v-if="idUser == messageSender" class="status">
            <icon-svg
              v-if="
                message.local_status !== 'sending' &&
                message.local_status !== 'error'
              "
              :variant="'message-checkmark'"
            />
            <icon-svg
              v-if="message.is_seen"
              class="seen"
              :variant="'message-checkmark'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    user() {
      return this.$store.getters["user"];
    },
    idUser() {
      return this.user.id;
    },
    messageSender() {
      return this.message.sender;
    },
    formattedText() {
      let text = this.message.text;

      const urlRegex = /(https?:\/\/[^\s]+)/g;
      text = text.replace(urlRegex, (url) => {
        let shortUrl = url;
        if (url.length > 90) {
          shortUrl = url.slice(0, 90) + "...";
        }
        return `<a href="${url}" target="_blank">${shortUrl}</a>`;
      });

      return text;
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.message.is_seen && this.messageSender !== this.idUser) {
        this.madeSeen();
      }
    });
  },
  methods: {
    resendMessage() {
      this.$emit("resendMessage", this.message.uid);
    },
    async madeSeen() {
      await this.$api.post(`/messages/makeSeen/${this.message.id}`);
      //   this.$store.dispatch("notifications/fetchNotifications");
    },
    formatDate(date) {
      if (this.$moment(date).isSame(new Date(), "day")) {
        return this.$moment(date).format("HH:mm");
      }
      // check if it was this week
      else if (this.$moment(date).isSame(new Date(), "week")) {
        return this.$moment(date).format("DD.MM HH:mm");
      } else {
        return this.$moment(date).format("DD.MM.YYYY HH:mm");
      }
    },
  },
};
</script>

<style lang="scss">
.chat__messange {
  display: flex;
  min-width: 100% !important;
  margin: 12px 0px;
  font-family: "Fixel Text SH";
  font-style: normal;
  font-variation-settings: "wght" 400;
  font-size: 14px;
  line-height: 1.2;
  .messages-chat__image {
    aspect-ratio: 1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    margin-right: 16px;
    width: 32px;
    height: 32px;
    min-width: 32px;
  }
  .text {
    padding: 12px 20px 12px 20px;
    border-radius: 12px;
    width: auto;
    max-width: 800px;
    word-break: break-word;
    min-width: 60px;
    background: #f2f4f5;
    position: relative;
    display: inline-flex;
    @media (max-width: 768px) {
      max-width: 70%;
    }
  }
  .service-info {
    font-size: 11px;
    margin-top: 6px;
    padding-left: 4px;
    .resend {
      a {
        color: #f00;
      }
    }
    .status {
      padding-right: 5px;
      position: relative;
      svg {
        width: 12px;
        height: 12px;
        margin-left: 5px;
        fill: var(--primary-color);
        top: 0;
        &.seen {
          position: absolute;
          transform: translateX(-90%);
        }
      }
    }
  }
  &:not(.your) {
    .text {
      border-top-left-radius: 0;
      margin-right: auto;
    }
  }
  &.your {
    justify-content: end;
    align-items: flex-end;

    .text {
      background: var(--primary-color);
      color: #ffffff;
      margin-left: auto;
      border-bottom-right-radius: 0;
      text-align: left;
      span {
        color: white;
      }
      a {
        color: #fff !important;
      }
    }

    .messages-chat__image {
      display: none;
    }
  }
  span {
    font-family: "Fixel Text SH";
    font-style: italic;
    font-variation-settings: "wght" 400;
    font-size: 10px;
    line-height: 1.2;
    color: #000000;
    opacity: 0.75;
    position: absolute;
    bottom: 0;
    right: 0;
    bottom: 8px;
    right: 12px;
  }
}
</style>
