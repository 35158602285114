<template>
  <div class="chat-header p-3">
    <div class="chat-header__contact d-flex align-items-center">
      <div class="contact d-inline-flex align-items-center">
        <div class="mr-2">
          <div class="contact-image">
            {{ displayName[0] }}
          </div>
        </div>
        <div class="contact-info">
          <div class="name d-none d-md-block">
            {{ displayName }}
          </div>
        </div>
      </div>
      <div class="ms-auto text-right">
        <b-button
          variant="link"
          size="sm"
          class="ms-auto text-danger me-1 me-md-none"
          @click="startReporting"
        >
          <b-icon-flag />
        </b-button>
        <b-button
          variant="link"
          size="sm"
          class="d-inline-block d-lg-none ms-0"
          @click.prevent="clearActiveChat"
        >
          <icon-svg style="transform: rotate(90deg)" :variant="'close'" />
        </b-button>
      </div>
    </div>
    <div class="chat-header__house d-flex mt-2 align-items-center">
      <div
        class="map-image me-3 d-none d-lg-block"
        :style="'background-image: url(' + mapImgSrc + ')'"
      ></div>
      <div class="info d-block d-lg-flex w-100 align-items-center">
        <div class="pe-3">
          <div class="house-title">
            {{ chat.house ? chat.house.location_title : "" }}
          </div>
          <div class="info-meta">
            <icon-svg :size="12" variant="persons" />
            <small>
              {{ chat.house.persons }}
            </small>
          </div>
        </div>
        <div class="ms-lg-auto">
          <b-button
            variant="outline-primary"
            block
            size="xs"
            class="mt-2 mt-lg-0 w-100"
            target="_blank"
            :href="'/offer/' + chat.house.id"
          >
            Переглянути огоолошення
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    chat: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    contact: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    mapImgSrc() {
      let url = require("@/assets/img/fullmap.png");
      if (
        this.chat.house &&
        this.chat.house.location_lat &&
        this.chat.house.location_lng
      ) {
        const lat = this.chat.house.location_lat;
        const lng = this.chat.house.location_lng;

        // Construct the Google Maps static map URL with latitude and longitude
        url = `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=10&size=400x400&key=AIzaSyAfNssi3issHmfxP3Run4A7Uaearyag8zI`;
      }

      return url;
    },
    job() {
      return this.chat.job || {};
    },
    displayName() {
      let name = "Без імені";
      if (this.contact && this.contact.first_name) {
        name = this.contact.first_name;
      }
      return name;
    },
  },
  methods: {
    startReporting() {
      const event = new CustomEvent("show-report-house-modal", {
        detail: { house: this.chat.house },
      });
      window.dispatchEvent(event);
    },
    clearActiveChat() {
      this.$emit("clearActiveChat");
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-header {
  background: #fff;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  &__house {
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    padding: 8px;
    max-width: 100%;
    .map-image {
      height: 48px;
      width: 48px;
      min-width: 48px;
      border-radius: 12px;
      background-color: #e7e7e7;
      background-size: cover;
      background-position: center;
    }
    .house-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 240px;
      font-size: 14px;
    }
  }
  .contact {
    &-image {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: var(--yellow);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 600;
      margin-right: 8px;
    }
    &-info {
      line-height: 1.1;
      .name {
        font-size: 16px;
        font-weight: 600;
      }
      .location {
        font-size: 16px;
        //overflow with dots
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  &__contact {
    .info {
      a {
        color: #000;
        font-size: 13px;
      }
      h5 {
        font-variation-settings: "wght" 700;
        font-size: 16px;
        line-height: 1.2;
      }
    }
  }
}
</style>
