<template>
  <div
    class="icon"
    :style="{
      backgroundColor: color,
    }"
  >
    <img :src="require('@/assets/img/' + image)" alt="affiliate" />
  </div>
</template>

<script>
export default {
  props: {
    image: String,
    color: {
      type: String,
      default: "#F9F9F9",
    },
  },
  computed: {
    imageUrl() {
      return this.image || "@/assets/img/home-1.png";
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  height: 128px;
  width: 128px;
  min-width: 128px;
  border-radius: 50%;
  margin-bottom: 24px;
  align-items: center;
  display: flex;
  justify-content: center;
  line-height: 1 !important;
  @media (max-width: 768px) {
    height: 64px;
    width: 64px;
    min-width: 64px;
  }
  img {
    width: 50%;
    height: 50%;
    object-fit: contain;
  }
}
</style>
