<template>
  <div class="affiliate pt-5 mb-0">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6">
          <circle-icon :image="'home-1.png'" color="#FFD500" />
          <h4 class="mb-4">
            Як отримати компенсацію <br />
            власнику приміщення?
          </h4>
        </div>
        <div class="col-12 col-md-6">
          <div class="affiliate__item">
            <div class="number">01</div>
            Зареєструвати своє житло на Прихистку
            <div class="pt-3">
              <b-button variant="outline-primary"> Зареєструвати </b-button>
            </div>
          </div>
          <!-- /item -->
          <div class="affiliate__item">
            <div class="number">02</div>
            <strong> Не пізніше наступного дня</strong> подати заяву до органів
            місцевого самоврядування (виконавчого комітету), вказавши ім’я та
            по-батькові кожної з розміщених осіб та додаючи копії документів, що
            посвідчують їх особи
            <div class="mt-3"></div>
            <a
              href="/files/Application_form.docx"
              class="btn btn-outline-primary"
              target="_blank"
              download
            >
              Завантажити бланк заяви</a
            >
          </div>
          <!-- /item -->
          <div class="affiliate__item">
            <div class="number">03</div>
            В день припинення розміщення внутрішньопереміщеної особи або зміни
            їх кількості подати заяву з інформацією про зміну переліку
            проживаючих осіб
          </div>
          <!-- /item -->
          <div class="affiliate__item">
            <div class="number">04</div>
            Звернутись
            <strong> до виконавчого комітету</strong> сільської, селищної,
            міської ради за місцем розташування жилого приміщення для отримання
            грошової допомоги
          </div>
          <!-- /item -->
          <div class="affiliate__item">
            <div class="number">05</div>
            Виплата здійснюється у безготівковій формі за вказаними у заяві
            банківськими реквізитами
            <strong>до 20-го числа наступного місяця</strong> за умови
            відсутності заборгованості по комуналці власника жила
          </div>
          <!-- /item -->
        </div>
      </div>
    </div>
    <callCenter />
    <div class="affiliate__calc">
      <div class="container mt-5">
        <div class="row">
          <div class="col-12 col-lg-6 pb-4">
            <div class="left">
              <circle-icon :image="'coins.png'" color="#FFD500"></circle-icon>
              <h4>
                Хто може отримати <br />
                компенсацію?
              </h4>
              <p>
                Громадяни України віком від 18 років, які є власниками жилих
                приміщень і безоплатно розміщують в цих приміщеннях внутрішньо
                переміщених осіб
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-6 pb-4">
            <div class="grey-card mb-3">
              <h5>Який розмір грошової допомоги від держави?</h5>
              <p>
                Cума компенсації визначається на рівні
                <strong>14,77 гривень.</strong> за кожен людино-день.
              </p>
            </div>
            <div class="grey-card">
              <h5>Розрахувати</h5>
              <div class="row">
                <!-- /item -->
                <div class="example-item col-12 col-sm-6 mb-2">
                  <span>К-сть осіб</span>
                  <div class="form-input">
                    <input type="number" @keyup="calcTotal" v-model="people" />
                  </div>
                </div>
                <!-- /item -->
                <div class="example-item col-12 col-sm-6 mb-2">
                  <span>К-сть днів</span>
                  <input type="number" @keyup="calcTotal" v-model="days" />
                </div>
                <!-- /item -->
              </div>
              <div
                class="total d-flex justify-content-between align-items-baseline w-100 pt-3"
              >
                <span>До виплати</span>
                <span class="amount"> {{ total }} грн. </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import callCenter from "@/components/call-center.vue";
export default {
  components: {
    callCenter,
  },
  data() {
    return {
      people: 2,
      days: 30,
      total: 0,
      period: "summer",
    };
  },
  computed: {
    per_day() {
      return 14.77;
    },
  },
  methods: {
    calcTotal() {
      this.total = parseFloat(this.per_day * this.people * this.days).toFixed(
        2
      );
    },
  },
  mounted() {
    this.calcTotal();
  },
};
</script>

<style lang="scss" scoped>
.affiliate {
  position: relative;
  z-index: 10;
  background: #fff;
  color: #000;
  margin-top: 0;
  &__calc {
    padding: 40px 0px;
  }
  h4 {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 16px;
  }
  h5 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 16px;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;
  }

  .total {
    .amount {
      font-size: 20px;
    }
  }
  &__item {
    position: relative;
    color: #000;
    padding-bottom: 40px;
    padding-left: 42px;
    font-size: 16px;
    .number {
      position: absolute;
      left: 0;
      font-size: 20px;
    }
  }
  .grey-card {
    background: #f7f7f7;
    border-radius: 12px;
    padding: 20px;
    &.blue {
      background: #e1f2fe;
    }

    .example-item {
      span {
        font-size: 18px;
        color: #666;
        font-weight: 400;
        margin-bottom: 6px;
      }
      input {
        border: 1px solid #dcdcdc;
        height: 48px;
        padding: 0px 12px;
        border-radius: 12px;
        width: 100%;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
      strong {
        font-size: 28px;
        font-weight: 600;

        line-height: 48px;
        @media (max-width: 768px) {
          font-size: 36px;
        }
        &.total {
          color: #0e2e69;
        }
      }
    }
  }
}
</style>
