<template>
  <div class="call-center">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="call-center__bg">
            <div class="row align-items-center">
              <div class="col-12">
                <circle-icon image="headphones.png" color="#F9F9F9" />
              </div>
              <div class="col-12 col-md-6">
                <h2 class="mb-3 mb-md-0">
                  Консультація щодо <br />
                  отримання компенсацій
                </h2>
              </div>
              <div class="col-12 col-md-6">
                <div class="phone">
                  <div
                    class="number justify-content-md-end d-flex align-items-center"
                  >
                    +38 (066) 813-62-39
                  </div>
                </div>
                <a
                  href="https://ukc.gov.ua/"
                  target="_blank"
                  class="d-flex align-items-center justify-content-start justify-content-md-end"
                >
                  <div class="flag me-3">
                    <img src="@/assets/img/trident.svg" alt="" />
                  </div>
                  Урядовий контактний центр - працює цілодобово
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" CircleIcon scoped>
.call-center {
  position: relative;
  z-index: 10;
  background: #fff;
  padding: 32px 0px;
  margin-top: 30px;
  color: #fff;
  a {
    font-size: 14px;
    text-decoration: none;
  }
  h2 {
    font-size: 32px !important;
    font-weight: 500;
  }
  &__icon {
    height: 128px;
    width: 128px;
    border-radius: 50%;
    background: #fff;
    margin-bottom: 24px;
  }
  .number {
    font-size: 32px;
  }
  .flag {
    img {
      height: 24px;
      filter: grayscale(1);
    }
  }

  &__bg {
    background: var(--light-blue);
    padding: 30px;
    color: #000;
    border-radius: 38px;
  }
}
</style>
