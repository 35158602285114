<template>
  <div class="footer">
    <footer class="py-4">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6">
            <h3 class="mb-0">Прихисток</h3>
            <p class="pt-4">
              © Member of Parliament of Ukraine Halyna Yanchenko 2022
            </p>
          </div>
          <div class="col-12 col-lg-6">
            <ul
              class="footer__menu d-flex flex-column flex-lg-row justify-content-center justify-content-lg-end"
            >
              <li>
                <a href="/my">Видалити своє оголошення</a>
              </li>
              <li>
                <a href="https://t.me/prykhystok_bot" target="_blank"
                  >Чат технічної підтримки</a
                >
              </li>
              <li>
                <a href="/donate"> Підтримати проект</a>
              </li>
            </ul>
            <div class="footer__socials text-center text-lg-end pt-0">
              <a href="https://bit.ly/yt_pryhystok" target="_blank">
                <b-icon-youtube />
              </a>
              <a href="https://bit.ly/тг_прихисток" target="_blank">
                <b-icon-telegram />
              </a>
              <a href="https://bit.ly/fb_pryhystok" target="_blank">
                <b-icon-facebook />
              </a>
            </div>
          </div>
          <div class="col-12">
            <div class="d-flex w-100 flex-column align-items-center"></div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
footer {
  // select grid area
  grid-area: footer;
  background: #000;
  padding-bottom: 80px !important;
  color: #fff;
  a {
    color: #fff;
  }
}
.footer {
  @media (max-width: 991px) {
    text-align: center;
  }
  &__socials {
    font-size: 28px;
    a {
      @media (max-width: 991px) {
        margin-left: 0px;
        margin-right: 20px;
      }
      @media (min-width: 991px) {
        display: inline-block;
        margin-left: 20px;
      }
      svg {
        width: 26px;
      }
    }
  }
  &__menu {
    padding: 0;
    @media (max-width: 991px) {
      margin: 40px 0px;
    }
    li {
      padding-bottom: 10px;
      @media (min-width: 991px) {
        padding-left: 20px;
      }
      @media (max-width: 991px) {
        padding-bottom: 20px;
      }
      a {
        text-decoration: none;
      }
    }
    list-style: none;
  }
}
</style>
