<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="donate-section">
          <div class="row">
            <div class="col-12 col-lg-6">
              <circle-icon image="heart.png" color="#F9F9F9" />
              <h2>Як підтримати проєкт</h2>
              <p>
                Проєкт "Прихисток" працює виключно на волонтерських засадах. Ми
                не отримуємо прибутку та виступаємо лише комунікаційним
                майданчиком між тими, хто потребує допомоги і тими, хто готовий
                її надати.
              </p>
              <p>
                Якщо Ви хочете підтримати проєкт - оберіть один із варіантів
              </p>
            </div>
            <div class="col-12 col-lg-6">
              <div
                v-for="(w, i) in wallets"
                :key="i"
                class="donate-section__wallets"
              >
                <div
                  @click="copyText(i)"
                  class="donate-section__wallets-single"
                >
                  <div class="title">
                    <div class="image">
                      <img :src="require(`@/assets/img/${w.icon}`)" alt="" />
                    </div>
                    {{ w.title }} Гаманець
                  </div>
                  <div class="wallet">
                    {{ w.wallet }}
                    <img src="@/assets/img/copy.svg" alt="" />
                  </div>
                  <div class="network">Мережа: {{ w.network }}</div>
                </div>
              </div>
              <a href="#" target="_blank" class="donate-section__card d-none">
                <div class="title">
                  <div class="image">
                    <b-icon-credit-card-fill
                      style="color: #fff"
                    ></b-icon-credit-card-fill>
                  </div>
                  Переказ на карту
                </div>
                <div class="arrow">
                  <b-icon-arrow-right
                    style="color: var(--primary-blue)"
                  ></b-icon-arrow-right>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      wallets: [
        {
          icon: "usdt.svg",
          title: "USDT",
          wallet: "TUcEMgWTs3kgePgp7Nzhm4KiQ4dji7kFbJ",
          network: "TRC-20",
        },
        {
          icon: "btc.svg",
          title: "BTC",
          wallet: "1D5qv55LgqHvNmSZk9HNCthbC6Mfn11yEz",
          network: "Bitcoin",
        },
        {
          icon: "eth.svg",
          title: "Ethereum",
          wallet: "0x4eb7fb01f59b08e20511a580b56903c1ca06edd7",
          network: "ERC20",
        },
      ],
    };
  },
  methods: {
    copyText(index) {
      const textarea = document.createElement("textarea");
      textarea.value = this.wallets[index].wallet;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      this.$toast.success("Скоопійовано");
    },
  },
};
</script>

<style lang="scss" scoped>
.donate-section {
  padding: 104px 0;
  h2 {
    font-size: 32px;
    margin-bottom: 24px;
  }
  p {
    font-size: 16px;
    margin-bottom: 16px;
    &:last-child {
      font-weight: 500;
    }
  }
  .icon {
    height: 128px;
    width: 128px;
    background: #f9f9f9;
    border-radius: 50%;
    margin: 8px 0 16px;
  }
  &__wallets {
    &-single {
      cursor: pointer;
      background: #195dc7;
      border-radius: 24px;
      padding: 24px;
      margin-bottom: 24px;
      transition: 0.2s;
      .title {
        display: flex;
        font-size: 16px;
        margin-bottom: 8px;
        align-items: center;
        .image {
          min-width: 32px;
          height: 32px;
          aspect-ratio: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background: #477dd2;
          margin-right: 16px;
        }
      }
      .wallet {
        position: relative;
        font-size: 20px;
        word-break: break-all;
        margin-bottom: 8px;
        display: flex;
        padding-right: 30px;
        img {
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
      .network {
        font-size: 16px;
      }
      &:hover {
        background: lighten(#195dc7, 5%);
        transition: 0.2s;
      }
    }
  }
  &__card {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background: #195dc7;
    color: #fff;
    text-decoration: none;
    border-radius: 24px;
    padding: 24px;
    margin-bottom: 24px;
    transition: 0.2s;
    .title {
      display: flex;
      align-items: center;
      font-size: 16px;
      .image {
        min-width: 32px;
        height: 32px;
        aspect-ratio: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #477dd2;
        margin-right: 16px;
      }
    }
    .arrow {
      border-radius: 50%;
      min-width: 32px;
      height: 32px;
      aspect-ratio: 1;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    button {
      aspect-ratio: 1;
    }
    &:hover {
      background: lighten(#195dc7, 5%);
      transition: 0.2s;
    }
  }
}
</style>
