<template>
  <b-modal v-model="show" centered hide-footer hide-header>
    <h2 class="mb-4 d-flex align-items-center">
      <img src="@/assets/img/telegram_icon.svg" height="48" alt="" />
      <div class="ps-3">Сповіщення в Telegram</div>
    </h2>
    <telegramAdd :inModal="true" :key="key" />
    <div>
      <b-button
        @click="show = false"
        class="w-100 mt-2"
        variant="outline-primary"
        >Закрити</b-button
      >
    </div>
  </b-modal>
</template>

<script>
import telegramAdd from "@/components/my/account/telegram.vue";
export default {
  components: {
    telegramAdd,
  },
  data() {
    return {
      show: false,
      key: 0,
    };
  },
  computed: {
    telegramAdded() {
      return this.$store.getters.telegramAdded;
    },
  },
  watch: {
    show() {
      this.key++;
    },
  },
  mounted() {
    window.addEventListener("openTelegramAddModal", () => {
      this.show = true;
    });
  },
};
</script>

<style lang="scss" scoped></style>
