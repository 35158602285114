<template>
  <div class="profile">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-4">
          <b-card class="mb-4">
            <h3>
              <img src="@/assets/img/man-edit-account.svg" alt="" />
              Керування акаунтом
            </h3>
            <div class="profile__tabs d-none d-lg-flex">
              <a
                v-for="(t, i) in tabs"
                :key="i"
                :class="{ active: t.active }"
                @click="setActiveTab(i)"
                href="#"
                >{{ t.title }}</a
              >
            </div>
            <div class="profile__tabs-select d-flex d-lg-none">
              <select name="tabs" v-model="selectedTab">
                <option v-for="(t, i) in tabs" :key="i" :value="i">
                  {{ t.title }}
                </option>
              </select>
              <b-icon-chevron-down />
            </div>
          </b-card>
        </div>
        <div class="col-12 col-lg-8" v-if="selectedTab == 0">
          <b-card>
            <div class="row">
              <div class="col-12">
                <h2>Особисті дані</h2>
                <p class="profile__subtitle">
                  Оновіть свою інформацію та дізнайтеся, як вона
                  використовується.
                </p>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-input mb-3 mb-md-0">
                  <label for="first_name">Ім'я</label>
                  <b-form-input
                    id="first_name"
                    v-model="user.first_name"
                    placeholder="Ім'я"
                    :disabled="isLoading"
                  ></b-form-input>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-input mb-3 mb-md-0">
                  <label for="last_name">Прізвище</label>
                  <b-form-input
                    id="last_name"
                    v-model="user.last_name"
                    placeholder="Прізвище"
                    :disabled="isLoading"
                  ></b-form-input>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-input mt-3">
                  <label for="last_name">E-mail</label>
                  <b-form-input
                    id="email"
                    v-model="user.email"
                    placeholder="E-mail"
                    :disabled="true"
                  ></b-form-input>
                </div>
              </div>
              <div class="col-12 pt-4">
                <b-button
                  :disabled="isLoading"
                  @click="updateProfile"
                  variant="primary px-5"
                >
                  <b-spinner v-if="isLoading" small />
                  <span v-if="!isLoading">Зберегти дані</span>
                </b-button>
              </div>
            </div>
          </b-card>
          <div class="pt-4">
            <b-card>
              <h2>Номер телефону</h2>
              <p>
                Додайте номер телефону, щоб підтверджувати свою особу та мати
                можливість створювати оголошення та відгукуватися на них.
              </p>
              <verifyPhone />
            </b-card>
          </div>
        </div>
        <div class="col-12 col-lg-8" v-if="selectedTab == 1">
          <b-card>
            <h2>Сповіщення</h2>
            <p class="profile__subtitle">
              Отримуйте сповіщення про нові оголошення чи повідомлення
            </p>
            <telegram />
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import verifyPhone from "@/views/my/tabs/tab-verify.vue";
import telegram from "@/components/my/account/telegram.vue";
export default {
  components: {
    verifyPhone,
    telegram,
  },
  data() {
    return {
      user: {
        first_name: "",
        last_name: "",
      },
      isLoading: false,
      tabs: [
        { title: "Особисті дані", active: true },
        // { title: "Безпека", active: false },
        { title: "Сповіщення", active: false },
      ],
      selectedTab: 0,
    };
  },
  watch: {
    selectedTab(newVal) {
      this.setActiveTab(newVal);
    },
  },
  computed: {
    userData() {
      return this.$store.state.user;
    },
  },
  methods: {
    setActiveTab(index) {
      this.tabs.forEach((tab, i) => {
        tab.active = i === index;
      });
      this.selectedTab = index;
    },
    async updateProfile() {
      this.isLoading = true;
      await this.$api
        .post("/user/update", {
          first_name: this.user.first_name,
          last_name: this.user.last_name,
        })
        .then((res) => {
          console.log(res.data.success);
          if (res.data.success == true) {
            this.$store.commit("setUser", res.data.data);
            this.$toast.success("Дані успішно оновлено");
          } else {
            this.$toast.error("Помилка оновлення даних");
          }
        })
        .catch(() => {
          this.$toast.error("Помилка оновлення даних");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.setActiveTab(0);
    this.$nextTick(() => {
      this.user.first_name = this.userData.first_name || "";
      this.user.last_name = this.userData.last_name || "";
      this.user.email = this.userData.email || "";
    });
  },
};
</script>

<style lang="scss" scoped>
.profile {
  padding: 40px 0px 120px 0px;
  background: #f9f9f9;
  .card {
    padding: 12px;
  }
  h2 {
    font-size: 24px;
    margin-bottom: 6px;
  }
  &__subtitle {
    font-size: 16px;
    margin-bottom: 16px;
  }
  h3 {
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    img {
      margin-right: 8px;
    }
  }
  &__tabs {
    flex-direction: column;
    a {
      margin-bottom: 16px;
      text-decoration: none;
      transition: 0.3s;
      &.active {
        color: var(--primary-blue);
      }
      &:hover {
        color: var(--primary-blue);
        transition: 0.3s;
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  &__tabs-select {
    position: relative;
    height: 48px;
    width: 100%;
    border: #dcdcdc 2px solid;
    border-radius: 12px;
    align-items: center;
    justify-content: end;
    padding-right: 14px;
    select {
      color: var(--primary-blue);
      position: absolute;
      cursor: pointer;
      top: 1px;
      left: 0;
      right: 0;
      bottom: 0;
      background: 0 0;
      font-size: 16px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
      outline: 0;
      padding: 0 0 0 22px;
    }
  }
}
</style>
