<template>
  <svg
    class="svg-icon"
    :width="size"
    :height="size"
    :fill="color ? color : '#000'"
  >
    <use :href="'#icon-' + variant" />
  </svg>
</template>

<script>
export default {
  // props: ["variant", "color"],
  props: {
    variant: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: "#000",
    },
    size: {
      type: Number,
      required: false,
      default: 16,
    },
  },
};
</script>

<style lang="scss" scoped>
.svg-icon {
  // width: 16px;
  // height: 16px;
}
</style>
