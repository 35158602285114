<template>
  <div class="skeleton--house-card"></div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.skeleton--house-card {
  //animated gradient background
  background: linear-gradient(90deg, #ededed 25%, #d3d3d3 50%, #ededed 75%);
  background-size: 400% 400%;
  animation: gradient 4s ease infinite;
  border-radius: 12px;
  aspect-ratio: 4/1.6;
  margin-bottom: 16px;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
