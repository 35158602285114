import axios from "axios";

import store from "../store";

let baseURL = "https://us-central1-safehouse-ua.cloudfunctions.net/api";
if (window.location.href.indexOf("localhost") > -1) {
  baseURL = "http://localhost:5001/safehouse-ua/us-central1/api";
}

const axiosApi = axios.create({
  baseURL: baseURL,
});

//add interceptor to add token to all requests
axiosApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    let headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    config.headers = headers;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosApi.interceptors.response.use(
  (response) => {
    // If the response is successful, return the response data
    if (response.data.success === false) {
      if (response.data.error.status === 401) {
        localStorage.removeItem("token");
        store.commit("setUser", null);
        store.commit("setFavourites", []);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    }
    return response;
  },
  (error) => {
    if (error.status === 401) {
      localStorage.removeItem("token");
      store.commit("setUser", null);
      store.commit("setFavourites", []);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }

    // Reject the promise with the error to propagate the error handling
    return Promise.reject(error);
  }
);

let api = axiosApi;

export default api;
