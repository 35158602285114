<template>
  <div class="persons-input">
    <b-button variant="light" @click.prevent="personsPlus(-1)"
      ><b-icon-dash aria-label="Decrease value"
    /></b-button>
    <input disabled class="text-center" :value="value" type="number" />

    <b-button variant="light" @click.prevent="personsPlus(1)"
      ><b-icon-plus aria-label="Increase value"
    /></b-button>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      default: 1, // Minimum value
    },
    max: {
      type: Number,
      default: 100, // Maximum value
    },
  },
  data() {
    return {
      value: this.modelValue,
    };
  },
  watch: {
    modelValue(newVal) {
      this.value = newVal;
    },
  },
  methods: {
    personsPlus(step) {
      const newValue = this.value + step;
      if (newValue >= this.min && newValue <= this.max) {
        this.value = newValue;
        this.$emit("update", this.value);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.persons-input {
  display: flex;
  input {
    width: 48px;
    text-align: center;
    border: 1px solid #dcdcdc;
    border-radius: 12px;
    appearance: none;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    margin: 0px 4px;
  }
  .btn {
    aspect-ratio: 1;
    padding: 0;
    border-radius: 50% !important;
    border: 1px solid #dcdcdc;
    background: #fff;
  }
}
</style>
