<template>
  <div style="display: none">
    <svg
      id="icon-door"
      enable-background="new 0 0 32 32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <path
            d="m27 32h-22c-.5522461 0-1-.4472656-1-1s.4477539-1 1-1h22c.5522461 0 1 .4472656 1 1s-.4477539 1-1 1z"
          />
        </g>
        <g>
          <path
            d="m25 32h-18c-.5522461 0-1-.4472656-1-1v-30c0-.5527344.4477539-1 1-1h18c.5522461 0 1 .4472656 1 1v30c0 .5527344-.4477539 1-1 1zm-17-2h16v-28h-16z"
          />
        </g>
        <g><circle cx="21.026" cy="16" r="1" /></g>
      </g>
    </svg>
    <svg
      id="icon-calendar"
      enable-background="new 0 0 32 32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m20.2929688 16.0913086-5.6665039 5.6665039-2.9194336-2.9194336c-.390625-.390625-1.0234375-.390625-1.4140625 0s-.390625 1.0234375 0 1.4140625l3.6264648 3.6264648c.1953125.1953125.4511719.2929688.7070312.2929688s.5117188-.0976562.7070312-.2929688l6.3735352-6.3735352c.390625-.390625.390625-1.0234375 0-1.4140625s-1.0234374-.3906249-1.4140624.0000001z"
      />
      <path
        d="m25 3.9599609h-1.5v-.9599609c0-.5522461-.4477539-1-1-1s-1 .4477539-1 1v.9599609h-11v-.9599609c0-.5522461-.4477539-1-1-1s-1 .4477539-1 1v.9599609h-1.5c-2.7568359 0-5 2.2431641-5 5v16.0400391c0 2.7568359 2.2431641 5 5 5h18c2.7568359 0 5-2.2431641 5-5v-16.0400391c0-2.7568359-2.2431641-5-5-5zm-21 5c0-1.6542969 1.3457031-3 3-3h1.5v.9594727c0 .5522461.4477539 1 1 1s1-.4477539 1-1v-.9594727h11v.9594727c0 .5522461.4477539 1 1 1s1-.4477539 1-1v-.9594727h1.5c1.6542969 0 3 1.3457031 3 3v1.0097656h-24zm21 19.0400391h-18c-1.6542969 0-3-1.3457031-3-3v-13.0302734h24v13.0302734c0 1.6542969-1.3457031 3-3 3z"
      />
    </svg>
    <svg
      id="icon-menu"
      enable-background="new 0 0 512 512"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m128 102.4c0-14.138 11.462-25.6 25.6-25.6h332.8c14.138 0 25.6 11.462 25.6 25.6s-11.462 25.6-25.6 25.6h-332.8c-14.138 0-25.6-11.463-25.6-25.6zm358.4 128h-460.8c-14.138 0-25.6 11.463-25.6 25.6 0 14.138 11.462 25.6 25.6 25.6h460.8c14.138 0 25.6-11.462 25.6-25.6 0-14.137-11.462-25.6-25.6-25.6zm0 153.6h-230.4c-14.137 0-25.6 11.462-25.6 25.6 0 14.137 11.463 25.6 25.6 25.6h230.4c14.138 0 25.6-11.463 25.6-25.6 0-14.138-11.462-25.6-25.6-25.6z"
      />
    </svg>
    <svg
      version="1.1"
      id="icon-persons"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      style="enable-background: new 0 0 512 512"
      xml:space="preserve"
    >
      <g>
        <g>
          <path
            d="M437.02,330.98c-27.883-27.882-61.071-48.523-97.281-61.018C378.521,243.251,404,198.548,404,148
			C404,66.393,337.607,0,256,0S108,66.393,108,148c0,50.548,25.479,95.251,64.262,121.962
			c-36.21,12.495-69.398,33.136-97.281,61.018C26.629,379.333,0,443.62,0,512h40c0-119.103,96.897-216,216-216s216,96.897,216,216
			h40C512,443.62,485.371,379.333,437.02,330.98z M256,256c-59.551,0-108-48.448-108-108S196.449,40,256,40
			c59.551,0,108,48.448,108,108S315.551,256,256,256z"
          />
        </g>
      </g>
    </svg>

    <svg
      id="icon-close"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="m25 512a25 25 0 0 1 -17.68-42.68l462-462a25 25 0 0 1 35.36 35.36l-462 462a24.93 24.93 0 0 1 -17.68 7.32z"
        />
        <path
          d="m487 512a24.93 24.93 0 0 1 -17.68-7.32l-462-462a25 25 0 0 1 35.36-35.36l462 462a25 25 0 0 1 -17.68 42.68z"
        />
      </g>
    </svg>
    <svg viewBox="0 0 24 24" id="icon-send" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m22.1012 10.5616-19.34831-9.43824c-.1664-.08117-.34912-.12336-.53427-.12336-.67302 0-1.21862.5456-1.21862 1.21862v.03517c0 .16352.02005.32643.05971.48507l1.85597 7.42384c.05069.2028.22214.3526.42986.3757l8.15756.9064c.2829.0314.4969.2705.4969.5552s-.214.5238-.4969.5552l-8.15756.9064c-.20772.0231-.37917.1729-.42986.3757l-1.85597 7.4238c-.03966.1587-.05971.3216-.05971.4851v.0352c0 .673.5456 1.2186 1.21862 1.2186.18515 0 .36787-.0422.53427-.1234l19.34831-9.4382c.5499-.2682.8988-.8265.8988-1.4384s-.3489-1.1702-.8988-1.4384z"
      />
    </svg>
    <svg
      version="1.1"
      id="icon-message-checkmark"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      style="enable-background: new 0 0 512 512"
      xml:space="preserve"
    >
      <g>
        <g>
          <path
            d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0
			c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7
			C514.5,101.703,514.499,85.494,504.502,75.496z"
          />
        </g>
      </g>
    </svg>

    <svg
      id="icon-map-marker"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m16 1a11 11 0 0 0 -11 11c0 5.81 9.8 18.1 10.22 18.63a1 1 0 0 0 1.56 0c.42-.53 10.22-12.82 10.22-18.63a11 11 0 0 0 -11-11zm0 14a4 4 0 1 1 4-4 4 4 0 0 1 -4 4z"
      />
    </svg>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
