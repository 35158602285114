<template>
  <b-modal v-model="show" hide-footer hide-header centered>
    <h4 class="mt-2 mb-3">
      <b-icon-envelope class="me-2" />
      <span>Написати повідомлення</span>
    </h4>
    <div class="house" v-if="house && house.id">
      <b-alert variant="primary" show class="p-2">
        <strong>{{ house.location_title }}</strong>
        <div>
          <icon-svg variant="persons" /> Місць:
          <strong> {{ house.persons }}</strong>
        </div>
      </b-alert>
    </div>
    <div class="py-3 form-input" :class="{ error: validate && !valid.message }">
      <b-form-textarea
        v-model="message"
        placeholder="Введіть текст повідомлення"
        rows="5"
        max-rows="5"
      ></b-form-textarea>
    </div>
    <div class="controls d-flex flex-column">
      <b-alert :show="!isPhoneVerified">
        Для того щоб надіслати повідомлення,
        <a href="#" @click.prevent="openAddPhoneModal">
          підтвердіть номер телефону
        </a>
      </b-alert>
      <b-button
        :disabled="isLoading"
        variant="primary"
        @click="send"
        class="mb-2"
        v-if="isPhoneVerified"
      >
        <b-spinner small v-if="isLoading" />
        <span v-if="!isLoading">Надіслати </span></b-button
      >
      <b-button
        :disabled="isLoading"
        variant="outline-primary"
        @click="show = false"
        class="me-2"
        >Скасувати</b-button
      >
    </div>
  </b-modal>
</template>

<script>
import iconSvg from "../icon-svg.vue";
export default {
  components: { iconSvg },
  data() {
    return {
      isLoading: false,
      show: false,
      house: null,
      message: "",
      validate: false,
      result: {},
    };
  },
  computed: {
    valid() {
      const valid = {
        message: this.message.length > 10 && this.message.length < 500,
      };
      return valid;
    },
    isPhoneVerified() {
      return this.$store.getters["isPhoneVerified"];
    },
  },
  methods: {
    openAddPhoneModal() {
      const event = new CustomEvent("openPhoneVerifyModal");
      window.dispatchEvent(event);
    },
    async send() {
      this.validate = true;
      if (!this.valid.message) {
        if (this.message.length < 10) {
          this.$toast.error("Мінімальна кількість символів - 10");
        }
        if (this.message.length > 500) {
          this.$toast.error("Максимальна кількість символів - 500");
        }

        return;
      }

      this.isLoading = true;
      await this.$api
        .post("/messages/send", {
          house_id: this.house.id,
          text: this.message,
        })
        .then((result) => {
          this.result = result;
          this.$toast.success("Повідомлення надіслано");
          this.show = false;
        })
        .catch((err) => {
          console.error(err);
          this.$toast.error("Помилка відправлення повідомлення");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    window.addEventListener("write-a-message", (e) => {
      this.show = true;
      this.house = e.detail.house;
    });
  },
};
</script>

<style lang="scss" scoped></style>
