<template>
  <div>
    <b-modal v-model="showModal" hide-footer hide-header>
      <b-overlay :show="isLoading">
        <h5>Видалити оголошення?</h5>
        <div class="py-5">
          <h3>{{ house.location_title }}</h3>
          <p class="text-muted">
            від: {{ $moment(house.createdAt).format("DD.MM.YYYY") }}
          </p>
          <houseDetails />
        </div>
        <div class="controls d-flex flex-column">
          <b-button variant="outline-primary" class="mb-2" @click="hide"
            >Скасувати</b-button
          >
          <b-button @click="removeHouse" variant="danger">Видалити</b-button>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import houseDetails from "@/components/house/house-details.vue";
export default {
  components: {
    houseDetails,
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      house: {},
    };
  },
  methods: {
    show() {
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    removeHouse() {
      this.isLoading = true;
      this.$api
        .post(`/houses/delete/${this.house.id}/`)
        .then((response) => {
          if (response.data.success) {
            this.$emit("removed", this.house.id);
          }
          this.hide();
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    window.addEventListener("openRemoveModal", (e) => {
      this.house = e.detail.house;
      this.show();
    });
  },
};
</script>

<style lang="scss" scoped></style>
