<template>
  <div class="my-offers py-4 h-100">
    <div class="container h-100">
      <div class="row" v-if="dataLoaded && offers.length">
        <div class="col-12">
          <div
            class="d-flex w-100 mb-3 flex-column flex-sm-row align-items-sm-center justify-content-sm-between"
          >
            <h1>Мої оголошення</h1>
            <b-button href="/create" variant="primary">
              <b-icon-plus class="me-2" />
              Створити оголошення
            </b-button>
          </div>
        </div>
        <div class="col-12 col-md-8">
          <div
            v-for="offer in offers"
            :key="offer.id"
            class="card-holder"
            :class="{ removing: offer.id == removed_id }"
          >
            <my-offer-card :offer="offer" @update="getOffers" />
          </div>
        </div>
        <div class="col-12 col-md-4">
          <b-card>
            <circle-icon :image="'bell.png'" color="#F9F9F9" />
            <p>
              Оголошення, які Ви розмістили раніше можна редагувати або ж
              видалити, якщо вже знайшли кого заселити.
            </p>
            <p class="mb-0">
              Якщо Ви прихистили когось на короткий період, можете тимчасово
              деактивувати оголошення і активувати знову, коли вивільниться
              житло.
            </p>
          </b-card>
        </div>
      </div>
      <div class="row h-100" v-if="dataLoaded && !offers.length">
        <div class="col-12 h-100">
          <no-data image="home-1.png">
            <template #text> Оголошень поки немає </template>
            <template #actions>
              <b-button to="/create" variant="primary">
                <b-icon-plus class="me-2" />
                Створити оголошення
              </b-button>
            </template>
          </no-data>
        </div>
      </div>
    </div>
    <remove-modal @removed="removed" />
  </div>
</template>

<script>
import circleIcon from "@/components/circle-icon.vue";
import MyOfferCard from "@/components/my/my-offer-card.vue";
import removeModal from "@/components/modals/remove-modal.vue";

export default {
  components: {
    circleIcon,
    MyOfferCard,
    removeModal,
  },
  data() {
    return {
      isLoading: false,
      dataLoaded: false,
      removed_id: null,
      offers: [],
    };
  },

  methods: {
    async getOffers() {
      this.isLoading = true;
      await this.$api
        .get("/houses/getMy/")
        .then((response) => {
          this.offers = response.data;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.dataLoaded = true;
        });
    },
    removed(id) {
      this.removed_id = id;
      window.setTimeout(() => {
        this.offers = this.offers.filter((offer) => offer.id !== id);
        this.removed_id = null;
      }, 600);
    },
  },
  mounted() {
    this.getOffers();
  },
};
</script>

<style lang="scss" scoped>
.my-offers {
  background: #f9f9f9;
  .card-holder {
    transition: all 0.3s;
    &.removing {
      opacity: 0 !important;
      transform: translateX(100%);
      transition: all 0.3s;
    }
  }

  &__empty {
    width: 100%;
    min-height: 70vh;
    .card-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    &-subtitle {
      font-size: 16px;
      margin-bottom: 16px;
    }
    .icon {
      margin-bottom: 24px;
    }
  }

  .icon {
    height: 128px;
    width: 128px;
    background: #f9f9f9;
    border-radius: 50%;
    margin: 8px 0 16px;
  }
  p {
    font-size: 14px;
  }
}
</style>
