<template>
  <div>
    <div :class="{ error: validate && !valid.region }" class="form-input pt-3">
      <h6>Ваш регіон <span class="required">*</span></h6>
      <v-select
        placeholder="Оберіть регіон"
        v-model="address.region_id"
        :reduce="(region) => region.value"
        :options="regionOptions"
        @option:selected="getRegions"
      ></v-select>
    </div>
    <div :class="{ error: validate && !valid.city }" class="form-input pt-3">
      <h6>Нас. пункт <span class="required">*</span></h6>
      <v-select
        placeholder="Оберіть нас. пункт"
        v-model="address.city"
        :disabled="!address.region_id || loading.rajons"
        :reduce="(rajon) => rajon.value"
        @option:selected="getStreets"
        :options="rajonOptions"
      ></v-select>
    </div>
    <div :class="{ error: validate && !valid.city }" class="form-input pt-3">
      <h6>Назва вулиці <span class="required">*</span></h6>
      <v-select
        placeholder="Оберіть вулицю"
        v-model="address.street"
        :disabled="!address.city || loading.streets"
        :reduce="(street) => street.value"
        @option:selected="getBuildings"
        :options="streetOptions"
      ></v-select>
    </div>
    <div :class="{ error: validate && !valid.city }" class="form-input pt-3">
      <h6>Номер будинку <span class="required">*</span></h6>
      <v-select
        placeholder="Оберіть номер будинку"
        v-model="address.buildCode"
        :disabled="!address.street || loading.buildings"
        :reduce="(building) => building.value"
        :options="buildingOptions"
      ></v-select>
    </div>
  </div>
</template>

<script>
import regions from "@/assets/data/regions.json";
import _ from "lodash";
export default {
  props: {
    house: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      regions: regions,

      //CVK address Data
      rajons: [],
      streets: [],
      buildings: [],

      //Fetching statuses
      loading: {
        rajons: false,
        streets: false,
        buildings: false,
      },

      //Address inputs to emit
      address: {
        region_id: 0,
        street: null,
        buildCode: null,
      },

      validate: false,
    };
  },
  watch: {
    address: {
      deep: true,
      handler() {
        this.$emit("updateAddress", this.address);
      },
    },
  },
  computed: {
    valid() {
      let validate = {};

      return validate;
    },
    regionOptions() {
      let options = [];
      options = this.regions.map((el) => {
        return {
          label: el.name_ua,
          value: el.ato_id,
        };
      });
      return options;
    },
    rajonOptions() {
      let options = [];
      if (this.rajons.length) {
        options = this.rajons.map((el) => {
          return {
            label: el.ato_name,
            value: el,
          };
        });
      }
      return _.orderBy(options, ["label"], ["asc"]);
    },
    streetOptions() {
      let options = [];
      if (this.streets.length) {
        options = this.streets.map((el) => {
          return {
            label: el.geon_name,
            value: el,
          };
        });
      }
      return _.orderBy(options, ["label"], ["asc"]);
    },
    buildingOptions() {
      let options = [];
      if (this.buildings.length) {
        options = this.buildings.map((el) => {
          return {
            label: Number(el.bld_num),
            value: el,
          };
        });
      }
      return _.orderBy(options, ["label"], ["asc"]);
    },
  },
  methods: {
    async getRegions() {
      this.loading.rajons = true;
      let url =
        "/cvk/getRegions/?region_id=" + this.address.region_id.toString();
      await this.$api
        .get(url)
        .then((res) => {
          this.rajons = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
      this.loading.rajons = false;
    },
    async getStreets() {
      this.loading.streets = true;
      let url = "/cvk/getStreets/?ato_id=" + this.address.city.ato_id;
      await this.$api
        .get(url)
        .then((res) => {
          this.streets = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
      this.loading.streets = false;
    },
    async getBuildings() {
      this.loading.buildings = true;
      let url = "/cvk/getBuildings/?geon_id=" + this.address.street.geon_id;
      await this.$api
        .get(url)
        .then((res) => {
          this.buildings = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
      this.loading.buildings = false;
    },
  },
  mounted() {
    if (this.house.region) {
      let region_id = this.regionOptions.find(
        (el) => el.label == this.house.region
      );
      if (region_id) {
        this.address.region_id = region_id.value;
        this.getRegions();
      }
    }
  },
};
</script>

<style lang="scss" scoped></style>
