import Vue from "vue";
import store from "../store";

import VueRouter from "vue-router";
import Home from "../views/home";
import Create from "../views/create/new";
import Find from "../views/find-new";
import MyOffers from "../views/my/offers";
import MyProfile from "../views/my/profile";
import MyMessages from "../views/my/messages";
import SendToOms from "../views/send-to-oms";
// import Single from "../views/single";
import Faq from "../views/faq";
import faqNew from "../views/faq-new";
import Donate from "../views/donate";
import thankYou from "../views/donate/thank-you.vue";
import login from "../views/login";
import registration from "../views/registration";
import forgotPassword from "../views/forgot-password";
import resetPassword from "../views/reset-password";
import singleOffer from "../views/offer/single.vue";

import myFavorites from "../views/my/favourites";
import MySubscriptions from "../views/my/subscriptions";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      role: "public",
    },
  },
  {
    path: "/create",
    name: "create",
    component: Create,
    meta: {
      role: "public",
    },
  },
  {
    path: "/find",
    name: "find",
    component: Find,
    meta: {
      role: "public",
    },
  },

  {
    path: "/offer/:id",
    name: "single",
    component: singleOffer,
    meta: {
      role: "public",
    },
  },
  {
    path: "/my",
    name: "my",
    redirect: "/my/offers",
    meta: {
      role: "user",
    },
  },
  {
    path: "/my/offers",
    name: "my-offers",
    component: MyOffers,
    meta: {
      role: "user",
    },
  },
  {
    path: "/my/subscriptions",
    name: "my-subscriptions",
    component: MySubscriptions,
    meta: {
      role: "user",
    },
  },
  {
    path: "/my/favourites",
    name: "my-favourites",
    component: myFavorites,
    meta: {
      role: "user",
    },
  },
  {
    path: "/my/profile",
    name: "my-profile",
    component: MyProfile,
    meta: {
      role: "user",
    },
  },
  {
    path: "/my/messages",
    name: "my-messages",
    component: MyMessages,
    meta: {
      role: "user",
    },
  },
  {
    path: "/send-to-oms/:uid",
    name: "send-to-oms",
    component: SendToOms,
    role: "advanced",
  },

  {
    path: "/donate",
    name: "donate",
    component: Donate,
    meta: {
      role: "public",
    },
  },
  {
    path: "/donate/thank-you/:order_id",
    name: "thank-you",
    component: thankYou,
    meta: {
      role: "public",
    },
  },
  {
    path: "/login",
    name: "login",
    component: login,
    meta: {
      role: "anonymous",
    },
  },
  {
    path: "/registration",
    name: "registration",
    component: registration,
    meta: {
      role: "anonymous",
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: forgotPassword,
    meta: {
      role: "anonymous",
    },
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: resetPassword,
    meta: {
      role: "anonymous",
    },
  },
  {
    path: "/faq",
    name: "FAQ",
    component: Faq,
    meta: {
      role: "public",
    },
  },
  {
    path: "/faq-new",
    name: "FAQ-new",
    component: faqNew,
    meta: {
      role: "public",
    },
  },
];

const router = new VueRouter({
  scrollBehavior(to, from) {
    if (to.name !== from.name) {
      window.scrollTo(0, 0);
    }
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isUser = store.getters.isUser;

  //public routes
  if (to.meta.role && to.meta.role === "public") {
    next();
  }
  //user routes
  if (to.meta.role && to.meta.role === "user") {
    if (isUser) {
      next();
    } else {
      next("/");
    }
  }
  if (to.meta.role && to.meta.role === "anonymous") {
    if (!isUser) {
      next();
    } else {
      next("/");
    }
  }
  return false;
});

export default router;
