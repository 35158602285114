<template>
  <div class="container">
    <div class="row">
      <div class="col-12 py-4 my-4">
        <h1 class="text-center">
          🙏 ❤️ 😻<br />
          <strong class="d-block mt-3">
            Дякуємо за вашу небайдужість у складні часи!
          </strong>
        </h1>
        <h4 class="text-center">
          Ваш внесок буде спрямовано на функціонування проекту та допомогу тим,
          хто дійсно її потребує.
          <br />
          <strong class="d-block mt-4">🇺🇦 Переможемо разом! </strong>
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      order: {},
      order_id: null,
    };
  },
  methods: {
    async getOrder() {
      this.isLoading = true;
      let args = {
        collectionName: "donations",
        where: ["order_id", "==", this.order_id],
      };
      let result = await this.$db.getCollection(args);
      this.order = result.docs[0];
      this.isLoading = false;
    },
  },
  mounted() {
    this.order_id = this.$route.params.order_id;
    this.getOrder();
  },
};
</script>

<style lang="scss" scoped></style>
