<template>
  <b-modal v-model="show" hide-footer hide-header no-close-on-backdrop centered>
    <div v-if="!phoneVerified">
      <h2>Номер телефону</h2>
      <p>
        Додайте номер телефону, щоб підтверити свою особу та мати можливість
        створювати оголошення та відгукуватися на них.
      </p>
    </div>
    <verifyPhone :inModal="true" />
    <b-button
      class="mt-2 w-100"
      variant="outline-primary"
      @click="show = false"
    >
      Закрити
    </b-button>
  </b-modal>
</template>

<script>
import verifyPhone from "@/views/my/tabs/tab-verify.vue";
export default {
  components: {
    verifyPhone,
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    phoneVerified() {
      return this.$store.getters["isPhoneVerified"];
    },
  },
  mounted() {
    window.addEventListener("openPhoneVerifyModal", () => {
      this.show = true;
    });
  },
};
</script>

<style lang="scss" scoped></style>
