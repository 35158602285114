<template>
  <div class="find">
    <div class="find__header">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1>Знайти житло</h1>
          </div>
          <div class="col-12">
            <div class="find__header-location row">
              <div class="col-12 col-md-7 col-lg-8 mb-2">
                <div class="location-input-holder">
                  <div class="icon">
                    <b-icon-geo-alt />
                  </div>
                  <searchLocation
                    @setLocation="setLocation"
                    :placeholder="'Населений пункт'"
                  />
                </div>
              </div>
              <div class="col-12 col-md-2 col-lg-2 p-md-0 mb-2">
                <div class="fake-select">
                  <select v-model="radius" name="" id="">
                    <option
                      v-for="option in radiusOptions"
                      :key="option.value"
                      :value="option.value"
                    >
                      {{ option.text }}
                    </option>
                  </select>
                  <span class="icon">
                    <b-icon-chevron-down size="16" />
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-3 col-lg-2">
                <b-button
                  @click="findOffers"
                  variant="warning"
                  size="lg"
                  class="w-100 search"
                >
                  <b-icon-search class="me-2" />
                  Знайти житло
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="find__content">
      <div class="container">
        <div class="row">
          <div class="col-12 mb-3">
            <b-alert
              :show="location_title.length && hasFilters"
              variant="primary"
              class="p-3"
            >
              <div class="d-md-flex align-items-center">
                <b-icon-bell-fill class="me-2" />
                Підпишіться на оновлення, щоб отримувати сповіщення про нові
                оголошення за цими критеріями
                <a
                  href="#"
                  @click.prevent="startSubscribe"
                  class="ms-2 text-primary"
                >
                  Підписатися на пошук
                </a>
              </div>
            </b-alert>
          </div>
          <div class="col-12 col-md-4 d-none d-md-block">
            <b-card>
              <searchFilter @filter="doFilter" />
            </b-card>
          </div>
          <div class="col-12 col-md-8">
            <div class="d-flex">
              <div class="title">
                <h2 class="mb-0">Усі оголошення</h2>
                <div class="find__content-found">
                  Знайдено:
                  <strong>{{ count }}</strong>
                </div>
              </div>
              <b-button
                @click="toggleFilterModal"
                class="ms-auto"
                variant="outline-primary"
                v-if="isMobile"
              >
                <b-icon-funnel class="me-2" />
                Фільтр
              </b-button>
            </div>

            <HouseCardNew v-for="o in offers" :offer="o" :key="o.id" />
            <div v-if="isLoading">
              <skeleton-house-card v-for="i in 10" :key="i" />
            </div>
            <div class="controls py-3">
              <b-button
                @click="loadMore"
                variant="outline-primary"
                class="w-100"
                v-if="count > offers.length"
              >
                Завантажити ще
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showFilterModal" hide-header hide-footer>
      <div class="pb-5">
        <searchFilter @filter="doFilter" />
      </div>
      <b-button
        style="position: sticky; bottom: 40px"
        variant="warning"
        class="w-100"
        @click="toggleFilterModal"
      >
        <strong v-if="!isLoading"> Закрити </strong>
        <b-spinner v-if="isLoading" small></b-spinner>
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import HouseCardNew from "@/components/house-card-new.vue";
import searchFilter from "@/components/search/filter.vue";
import searchLocation from "@/components/search/locationInput.vue";
import skeletonHouseCard from "@/components/skeleton/house-card.vue";
export default {
  components: {
    HouseCardNew,
    searchFilter,
    skeletonHouseCard,
    searchLocation,
  },
  data() {
    return {
      isLoading: false,
      showButton: false,
      showFilterModal: false,
      page: 1,
      offers: [],
      filters: {},
      count: 0,
      location: {
        location_lat: null,
        location_lng: null,
      },
      location_title: "",
      radius: 5,
      radiusOptions: [
        { value: 5, text: "5 км" },
        { value: 10, text: "10 км" },
        { value: 15, text: "15 км" },
        { value: 20, text: "20 км" },
        { value: 25, text: "25 км" },
        { value: 30, text: "30 км" },
      ],
    };
  },
  computed: {
    isMobile() {
      let isMobile = false;
      if (window.innerWidth < 768) {
        isMobile = true;
      }
      return isMobile;
    },
    hasFilters() {
      return Object.keys(this.filters).length > 0;
    },
  },
  methods: {
    toggleFilterModal() {
      this.showFilterModal = !this.showFilterModal;
    },
    setLocation(location) {
      console.log(location);
      this.location.location_lat = location.location_lat;
      this.location.location_lng = location.location_lng;
      this.location_title = location.location_title;
    },
    createQueryString() {
      const query = {};
      for (const key in this.filters) {
        if (this.filters[key].length > 0) {
          query[key] = this.filters[key].join(",");
        }
      }

      if (this.radius) {
        query.radius = this.radius;
      }
      //add persons
      query.persons = this.filters.persons;
      //set query string

      const path = this.$route.path;

      let newQuery = "";
      for (const key in query) {
        if (key !== "location") {
          newQuery += key + "=" + query[key] + "&";
        }
      }
      // add location as location=lat,lng
      if (this.location.location_lat && this.location.location_lng) {
        newQuery +=
          "location=" +
          this.location.location_lat +
          "," +
          this.location.location_lng;
      }

      window.history.pushState({}, "", path + "?" + newQuery);
    },
    async loadMore() {
      this.page++;
      await this.getOffers();
    },
    doFilter(filters) {
      this.filters = filters;
      this.page = 1;
      this.offers = [];
      this.getOffers();
    },
    findOffers() {
      this.page = 1;
      this.offers = [];
      this.getOffers();
    },
    async getOffers() {
      this.isLoading = true;
      this.createQueryString();
      const query = {
        page: this.page,
        ...this.filters,
        ...this.location,
        radius: this.radius,
      };

      const response = await this.$api.post("/houses/search/", query);
      this.offers = [...this.offers, ...response.data.houses];
      this.count = response.data.count;
      this.isLoading = false;
    },
    startSubscribe() {
      const event = new CustomEvent("startSubscribe", {
        detail: {
          filters: this.filters,
          location: {
            location_lat: this.location.location_lat,
            location_lng: this.location.location_lng,
            location_title: this.location_title,
          },
          radius: this.radius,
        },
      });
      window.dispatchEvent(event);
    },
  },
  async mounted() {
    await this.getOffers();
  },
};
</script>

<style lang="scss" scoped>
.find {
  background: #f9f9f9;
  &__header {
    background: var(--primary-blue);
    padding: 40px 0px 20px;
    color: #fff;
    &-location {
      .btn {
        border-radius: 12px;
        font-size: 16px;
        white-space: nowrap;
        font-weight: 600;
        .icon {
          font-size: 14px !important;
        }
      }
      .fake-select {
        background: #fff;
        border-radius: 12px;
        height: 56px;
        position: relative;
        .icon {
          position: absolute;
          right: 16px;
          top: 50%;
          transform: translateY(-50%);
          color: #000;
          z-index: 1;
        }
        select {
          border: none;
          width: 100%;
          height: 100%;
          font-size: 16px;
          line-height: 1;
          appearance: none;
          border-radius: 12px;
          background: transparent;
          padding: 8px 16px;
          outline: none;
          position: relative;
          z-index: 2;
        }
      }
      .location-input-holder {
        background: #fff;
        border-radius: 12px;
        height: 56px;
        color: #000;
        position: relative;
        padding-left: 42px;
        .icon {
          position: absolute;
          left: 16px;
          top: 50%;
          transform: translateY(-50%);
          color: #000;
        }
        input {
          border: none;
          width: 100%;
          height: 100%;
          font-size: 24px;
          background: transparent !important;
          outline: none !important;
          border: none !important;
          box-shadow: none !important;
        }
      }
    }
  }
  &__content {
    padding: 20px 0px 40px;
    h2 {
      font-size: 32px;
      margin-bottom: 8px;
    }
    &-found {
      font-size: 16px;
      margin-bottom: 24px;
    }
  }
  h2 {
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }
}
</style>
