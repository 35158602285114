let person_types = [
  {
    value: 100,
    text: "Будь-кого",
  },
  {
    value: 200,
    text: "Сім’ї з дітьми (чоловік, дружина, діти)",
  },
  {
    value: 300,
    text: "Сім’ї без дітей (подружжя, пари)",
  },
  {
    value: 400,
    text: "Жінки",
  },
  {
    value: 500,
    text: "Діти",
  },
  {
    value: 600,
    text: "Особи старше шістдесяти років",
  },
  {
    value: 700,
    text: "Чоловіки",
  },
  {
    value: 800,
    text: "з тваринами (коти, собаки)",
  },
  {
    value: 900,
    text: "Особи з інвалідністю/хронічним захворюванням",
  },
  {
    value: 1000,
    text: "Одинокі батьки/опікуни чи їх представники",
  },
  {
    value: 1100,
    text: "Багатодітна сім’я",
  },
  {
    value: 1200,
    text: "Дитина без супроводу дорослих (під наглядом)",
  },
  {
    value: 1300,
    text: "Представники етнічних меншин",
  },
];
let report_reasons = [
  {
    value: 100,
    text: "за житло вимагають орендну плату",
  },
  {
    value: 200,
    text: "шахрайство",
  },
  {
    value: 300,
    text: "опис пропозиції не відповідає дійсності ",
  },
  {
    value: 400,
    text: "власник оголошення не виходить на зв’язок",
  },
  {
    value: 500,
    text: "відсутність  заявленої кількості місць",
  },
  {
    value: 700,
    text: "Оголошення про пошук а не пропозицію",
  },
  {
    value: 600,
    text: "інша причина",
  },
];
let remove_reasons = [
  {
    value: 100,
    text: "Вже заселили людей",
  },
  {
    value: 200,
    text: "Занадто багато людей дзвонить",
  },
  {
    value: 300,
    text: "Інша причина",
  },
];

let accomodation_types = [
  {
    value: 100,
    text: "Спальні місця",
  },
  {
    value: 200,
    text: "Окрема кімната",
  },
  {
    value: 300,
    text: "Житло повністю",
  },
  {
    value: 400,
    text: "Приміщення",
  },
];
let periods = [
  {
    value: 100,
    text: "1-2 ночі",
  },
  {
    value: 200,
    text: "Кілька днів",
  },
  {
    value: 300,
    text: "Кілька тижнів",
  },
  {
    value: 400,
    text: "До перемоги",
  },
  {
    value: 500,
    text: "На будь-який термін",
  },
];

export {
  person_types,
  remove_reasons,
  report_reasons,
  accomodation_types,
  periods,
};
