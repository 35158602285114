<template>
  <div class="my-offers py-4 h-100">
    <div class="container h-100">
      <div class="row h-100" v-if="dataLoaded && offers.length">
        <div class="col-12">
          <div
            class="d-flex w-100 mb-3 flex-column flex-sm-row align-items-sm-center justify-content-sm-between"
          >
            <h1>Збережені оголошення</h1>
            <b-button href="/find" variant="primary">
              <b-icon-search class="me-3" />
              Шукати оголошення
            </b-button>
          </div>
        </div>
        <div class="col-12 col-md-8">
          <div v-for="offer in offers" :key="offer.id" class="card-holder">
            <my-offer-card :offer="offer" @update="getFavourites" />
          </div>
        </div>
        <div class="col-12 col-md-4"></div>
      </div>
      <div class="row h-100" v-if="dataLoaded && !offers.length">
        <div class="col-12 h-100">
          <no-data image="star.png">
            <template #text>Збережених оголошень поки немає </template>
            <template #actions>
              <b-button href="/find" variant="primary">
                <b-icon-search class="me-3" />
                Шукати оголошення
              </b-button>
            </template>
          </no-data>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyOfferCard from "@/components/house-card-new.vue";

export default {
  components: {
    MyOfferCard,
  },
  data() {
    return {
      isLoading: false,
      dataLoaded: false,
      offers: [],
    };
  },

  methods: {
    async getFavourites() {
      this.isLoading = true;
      await this.$api
        .get("/houses/getFavourites/")
        .then((response) => {
          this.offers = response.data.favourites;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.dataLoaded = true;
        });
    },
  },
  mounted() {
    this.getFavourites();
  },
};
</script>

<style lang="scss" scoped>
.my-offers {
  background: #f9f9f9;
  .card-holder {
    transition: all 0.3s;
    &.removing {
      opacity: 0 !important;
      transform: translateX(100%);
      transition: all 0.3s;
    }
  }

  &__empty {
    width: 100%;
    min-height: 70vh;
    .card-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    &-subtitle {
      font-size: 16px;
      margin-bottom: 16px;
    }
    .icon {
      margin-bottom: 24px;
    }
  }

  .icon {
    height: 128px;
    width: 128px;
    background: #f9f9f9;
    border-radius: 50%;
    margin: 8px 0 16px;
  }
  p {
    font-size: 14px;
  }
}
</style>
