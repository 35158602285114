<template>
  <div>
    <div class="verify-width">
      <template v-if="isVerified">
        <div class="d-block d-md-flex align-items-md-center py-1">
          <img
            src="@/assets/verified.svg"
            style="height: 96px"
            alt=""
            class="me-2"
          />
          <div class="pt-2">
            <h4 class="mb-1">Ваш номер телефону верифіковано</h4>
            <p class="mb-2">Тепер ви можете публікувати оголошення</p>
            <strong>
              {{ user.phone }}
            </strong>
          </div>
        </div>
      </template>
      <template v-if="!isVerified">
        <p>
          <strong>
            Перед тим як надсилати СМС з кодом, переконайтесь, що телефон поруч
            з Вам та увімкнений
          </strong>
        </p>
        <b-overlay
          :show="isLoading"
          variant="white"
          rounded="sm"
          spinner-variant="warning"
        >
          <div v-show="step == 'phone'" class="pt-2">
            <div
              class="d-block mb-3"
              :class="{
                error: !checkLoading && phone_exists && phone_obj.valid,
              }"
            >
              <div
                :class="{ 'w-100': inModal }"
                style="width: 400px; max-width: 100%"
              >
                <vue-tel-input
                  @validate="validatePhone"
                  :inputOptions="inputOptions"
                  :mode="'international'"
                  :autoDefaultCountry="false"
                  :defaultCountry="'UA'"
                  v-model="phone"
                  :disabled="checkLoading"
                ></vue-tel-input>
              </div>
              <small
                class="error mb-0 mt-0"
                v-if="!checkLoading && phone_exists && phone_obj.valid"
              >
                Схоже, цей номер телефону вже використовується
              </small>
            </div>
            <div
              class="g-recaptcha"
              data-sitekey="6Leoem4lAAAAAHPSiT9kzQ8lYlyQuxj0BXMjI95d"
              data-callback="onSubmit"
            ></div>
            <b-button
              :disabled="!phone_obj.valid || phone_exists || checkLoading"
              variant="primary mt-2"
              id="verify-button"
              @click="sendCode"
              v-show="recaptchaResponse && recaptchaResponse.length > 0"
              :class="{ 'w-100': inModal }"
            >
              <b-spinner sm v-if="checkLoading || isLoading"></b-spinner>
              <span v-else>Отримати СМС з кодом</span>
            </b-button>
          </div>
          <div v-show="step == 'code'" class="pt-2">
            <div
              :class="{ 'w-100': inModal }"
              style="width: 400px; max-width: 100%"
            >
              <b-form-input class="mb-2" v-model="code"></b-form-input>
              <b-button
                :disabled="code.length < 5"
                @click="confirmCode"
                variant="primary"
                class="w-100"
              >
                Підтвердити
              </b-button>
            </div>
          </div>
        </b-overlay>
      </template>
    </div>
  </div>
</template>

<script>
import { VueTelInput } from "vue-tel-input";
import { mapGetters } from "vuex";

export default {
  components: {
    VueTelInput,
  },
  props: {
    inModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      checkLoading: false,
      step: "phone",
      phone: "",
      code_sent: false,
      code: "",
      phone_exists: false,
      confirmationResult: false,
      recaptchaResponse: "",
      phone_obj: {
        valid: false,
        number: "",
      },
      inputOptions: {
        dynamicPlaceholder: true,
        placeholder: "Номер телефону",
        enabledCountryCode: true,
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
      isVerified: "isPhoneVerified",
    }),
  },
  methods: {
    checkIfPhoneExists() {
      this.checkLoading = true;
      let body = {
        phone: this.phone_obj.number,
      };
      this.$api
        .post("/strapi/user/checkIfPhoneExists/", body)
        .then((response) => {
          if (response.data.exists) {
            this.phone_exists = true;
          } else {
            this.phone_exists = false;
          }
          this.checkLoading = false;
        })
        .catch((e) => {
          console.log(e);
          this.checkLoading = false;
        });
    },
    validatePhone(obj) {
      this.phone_obj = obj;
      if (obj.valid) {
        this.checkIfPhoneExists();
      }
    },
    sendCode() {
      this.isLoading = true;
      var body = {
        phone: this.phone_obj.number,
        recaptchaResponse: this.recaptchaResponse,
      };
      this.$api
        .post("/user/addPhone/", body)
        .then((response) => {
          if (response.data.status == "pending") {
            this.code_sent = true;
            this.isLoading = false;
            this.step = "code";
          } else {
            this.isLoading = false;
            this.$toast.error("Помилка");
          }
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
          this.$toast.error("Помилка");
        });
    },
    async confirmCode() {
      this.isLoading = true;
      var body = {
        phone: this.phone_obj.number,
        code: this.code,
        uid: this.user.uid,
      };
      await this.$api.post("/user/confirmPhone", body).then((response) => {
        this.isLoading = false;
        if (response.data.success == true) {
          this.$store.dispatch("fetchUserData");
        }
      });
      this.isLoading = false;
    },
  },
  mounted() {
    //include recaptcha script
    var script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    document.body.appendChild(script);
    //create onSubmit function for recaptcha
    window.onSubmit = (token) => {
      this.recaptchaResponse = token;
    };
  },
};
</script>

<style lang="scss" scoped>
.error {
  color: #f00;
}
.verify-width {
  max-width: 100%;
  margin-right: auto;
}
</style>
