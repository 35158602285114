<template>
  <div class="d-flex flex-wrap mb-2">
    <label
      v-for="option in options"
      :key="option.id"
      class="budge"
      :class="{
        'icon-badge': hasIcon,
        'active-badge': internalValue.includes(option.id),
        'disabled-badge': isDisabled,
      }"
    >
      <input
        type="checkbox"
        :value="option.id"
        v-model="internalValue"
        :name="type"
        :disabled="isDisabled"
        class="d-none"
      />
      <div v-if="hasIcon && option.key">
        <img
          :class="{ 'active-icon': internalValue.includes(option.id) }"
          :src="require(`@/assets/img/${option.key}.svg`)"
          alt="icon"
          class="budge-icon"
        />
      </div>
      <div v-else-if="hasIcon && !option.key">
        <img src="@/assets/img/days.svg" alt="" />
      </div>
      <div class="budge__title">
        {{ option.label }}
      </div>
    </label>
  </div>
</template>
<script>
export default {
  name: "CustomBadges",
  props: {
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    hasIcon: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalValue: [...this.value],
    };
  },
  watch: {
    // value(newValue) {
    //   this.$set(this, "internalValue", [...newValue]);
    // },
    internalValue(newValue) {
      this.$emit("select", {
        key: this.type,
        selected: newValue,
      });
    },
  },
  methods: {
    isChecked(id) {
      return this.internalValue.includes(id);
    },
  },
};
</script>

<style scoped lang="scss">
.budge {
  cursor: pointer;
  padding: 12px 16px;
  border-radius: 56px;
  border: 1px solid #dcdcdc;
  width: fit-content;
  margin-right: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  img {
    width: 24px;
    height: 24px;
  }
}
.budge-icon {
  margin-right: 8px;
}
.active-badge {
  background-color: #e1f1fe;
  color: #004bc1;
  border-color: #004bc1;
}
.active-icon {
  filter: invert(30%) sepia(100%) saturate(1000%) hue-rotate(190deg)
    brightness(80%) contrast(200%);
}
.disabled-badge {
  background-color: #f7f7f7;
  color: #a9a9a9;
  border-color: #a9a9a9;
  cursor: not-allowed;
}
.icon-badge {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  border-radius: 12px;
  margin-right: 16px;
  flex-grow: 1;
  img {
    margin-bottom: 24px;
  }
  @media screen and (max-width: 992px) {
    flex-basis: 180px;
    flex-grow: 1;
  }
  @media screen and (max-width: 426px) {
    flex-basis: 150px;
  }
}
</style>
