<template>
  <b-modal v-model="show" hide-footer hide-header centered>
    <h4>
      <span>Створення підписки за критеріями</span>
    </h4>
    <p>
      Ви отримуватимете сповіщення про нові оголошення, які відповідають
      наступним критеріям:
    </p>
    <div class="criterias">
      <b-alert show variant="primary">
        <h5>
          <strong>
            {{ location.location_title }}
          </strong>
          <div>
            <small> та в радіусі {{ radius }} км </small>
          </div>
        </h5>
        <div>
          Місць: <strong>{{ filters.persons }}</strong>
        </div>
        <div class="tags">
          <div class="tag" v-for="type in filters.persons_type" :key="type">
            <b-icon-check />
            {{ getLabel(type, "persons_type") }}
          </div>
          <div class="tag" v-for="period in filters.periods" :key="period">
            <b-icon-check />
            {{ getLabel(period, "periods") }}
          </div>
          <div
            class="tag"
            v-for="accomodation in filters.accomodation_type"
            :key="accomodation"
          >
            <b-icon-check />
            {{ getLabel(accomodation, "accomodation_types") }}
          </div>
        </div>
      </b-alert>
    </div>
    <div class="controls pt-2">
      <b-button
        :disabled="isLoading"
        variant="primary mb-2"
        class="w-100"
        @click="createSubscription"
      >
        <b-spinner v-if="isLoading" small />
        <span v-if="!isLoading"> Створити підписку </span>
      </b-button>
      <b-button variant="outline-primary w-100" @click="show = false">
        Скасувати
      </b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      show: false,
      filters: {},
      location: {},
      radius: 0,
    };
  },
  computed: {
    isUser() {
      return this.$store.getters["isUser"];
    },
    datasets() {
      return this.$store.getters["datasets"];
    },
  },
  methods: {
    getLabel(value, type) {
      let label = value;

      let items = this.datasets[type] || [];
      console.log(items);
      if (items.length) {
        let found = items.find((item) => Number(item.id) === Number(value));

        if (found) {
          label = found.label;
        }
      }

      return label;
    },
    async createSubscription() {
      this.isLoading = true;
      await this.$api
        .post("/subscriptions/create/", {
          accomodation_types: this.filters.accomodation_types || [],
          persons_type: this.filters.persons_type || [],
          location_lat: this.location.location_lat,
          location_lng: this.location.location_lng,
          location_title: this.location.location_title,
          radius: this.radius.radius || 15,
          persons: this.filters.persons || 1,
        })
        .then((response) => {
          if (response.data.success) {
            this.$toast.success("Підписка створена");
            this.show = false;
          } else {
            this.$toast.error("Помилка при створенні підписки");
          }
        })
        .catch(() => {
          this.$toast.error("Помилка при створенні підписки");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    window.addEventListener("startSubscribe", (e) => {
      if (!this.isUser) {
        const event = new CustomEvent("openAuth");
        window.dispatchEvent(event);
      }
      if (this.isUser) {
        this.filters = e.detail.filters;
        this.location = e.detail.location;
        this.radius = e.detail.radius;
        this.show = true;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  .tag {
    border: 1px solid var(--primary-blue);
    padding: 5px 8px;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 16px;
  }
}
</style>
