<template>
  <div>
    <b-overlay :show="isLoading" spinner-variant="primary" rounded="lg">
      <b-form @submit="createAccount">
        <h3 class="pb-2">Реєстрація</h3>

        <div class="input-group mb-2">
          <label class="d-block w-100">Ім'я</label>
          <b-form-input :disabled="isLoading" v-model.trim="user.first_name">
          </b-form-input>
        </div>
        <div class="input-group mb-4">
          <label class="d-block w-100">Прізвище</label>
          <b-form-input :disabled="isLoading" v-model.trim="user.last_name">
          </b-form-input>
        </div>
        <div class="input-group mb-2">
          <label class="d-block w-100">Email</label>
          <b-form-input :disabled="isLoading" v-model.trim="user.email">
          </b-form-input>
        </div>
        <div class="input-group mb-2">
          <label class="d-block w-100">Пароль</label>
          <b-form-input
            :disabled="isLoading"
            type="password"
            v-model.trim="user.password"
          >
          </b-form-input>
        </div>
        <div class="pb-2">
          <password
            :secureLength="6"
            v-model="user.password"
            :strength-meter-only="true"
          />
        </div>
        <div class="input-group mb-2">
          <label class="d-block w-100">Пароль повторно</label>
          <b-form-input
            :disabled="isLoading"
            type="password"
            v-model.trim="user.password_repeat"
          >
          </b-form-input>
        </div>
        <div class="mb-2 pt-3">
          <b-button
            :disabled="registerDisabled || isLoading"
            size="lg"
            variant="primary"
            class="w-100"
            @click="createAccount"
          >
            Створити аккаунт
          </b-button>
        </div>
      </b-form>
      <b-button
        block
        size="lg"
        :disabled="isLoading"
        variant="outline-primary  w-100 mt-2"
        @click.prevent="switchUsecase('login')"
        >Вхід</b-button
      >
    </b-overlay>
  </div>
</template>

<script>
import Password from "vue-password-strength-meter";

export default {
  components: {
    Password,
  },
  data() {
    return {
      isLoading: false,
      user: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        password_repeat: "",
      },
    };
  },
  computed: {
    registerDisabled() {
      let disabled = false;
      const email = this.user.email;

      //regex test email
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        disabled = true;
      }

      const password = this.user.password;
      const password_repeat = this.user.password_repeat;
      if (password.length < 6 || password != password_repeat) {
        disabled = true;
      }

      const first_name = this.user.first_name;
      const last_name = this.user.last_name;
      if (first_name.length < 2 || last_name.length < 2) {
        disabled = true;
      }

      return disabled;
    },
  },
  methods: {
    async createAccount() {
      this.isLoading = true;
      let result = await this.$auth.signUp(this.user);

      if (result.success == true) {
        setTimeout(() => {
          window.location.href = "/find";
        }, 1000);
      } else {
        this.$toast.error("Сталася помилка");
      }
      this.isLoading = false;
    },
    switchUsecase(use_case) {
      this.$emit("switch", use_case);
    },
  },
};
</script>

<style lang="scss" scoped></style>
