<template>
    <span v-if="show" class="form-input__validate mb-2 pt-1 align-items-center">
      <div class="error-validation">{{ label }}</div>
    </span>
  </template>
  <script>
  export default {
    props: {
      label: String,
      show: Boolean,
    },
  };
  </script>
  <style lang="scss" scoped>
  .form-input__validate {
    position: relative;
    // padding-left: 24px;
    // img {
    //   position: absolute;
    //   left: 0;
    //   top: 8px;
    // }
  }
  .error-validation {
    color: #f00 !important;
    font-size: 12px;
    font-weight: 500;
  }
</style>