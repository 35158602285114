<template>
  <b-modal
    :modal-class="'donate-modal'"
    v-model="showModal"
    hide-header
    hide-footer
  >
    <div class="donate donate-bg">
      <h1>❤️ Допомога проєкту</h1>
      <div ref="form" class="d-none" v-html="form"></div>
      <h4 class="mt-4">
        <strong> Оберіть суму </strong>
      </h4>
      <div class="donate__summary pt-2">
        <input type="number" @keyup="keyUp" v-model="amount" />
        <span> грн.</span>
      </div>
      <div class="donate__currency">≈ {{ in_usd }} USD</div>
      <div class="d-flex donate__options flex-wrap pt-4">
        <div
          class="donate__option"
          :class="{ selected: option.amount == amount }"
          v-for="option in donate_options"
          :key="option.amount"
          @click="chooseAmount(option.amount)"
        >
          {{ option.amount }} ₴
        </div>
      </div>
      <div class="pt-4 pb-4">
        <b-button
          @click="submitForm"
          :disabled="isLoading"
          class="donate w-100"
          variant="success"
          size="xl"
        >
          <b-spinner v-if="isLoading"></b-spinner>
          <span v-else> Надіслати кошти </span>
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: ["show"],
  data() {
    return {
      isLoading: false,
      showModal: false,
      amount: 100.0,
      currency: "UAH",
      timeout: null,

      donate_options: [
        {
          amount: 100.0,
        },
        {
          amount: 250.0,
        },
        {
          amount: 500.0,
        },
        {
          amount: 1000.0,
        },
        {
          amount: 5000.0,
        },
        {
          amount: 10000.0,
        },
        {
          amount: 25000.0,
        },
      ],
      form: "",
    };
  },
  computed: {
    in_usd() {
      return parseFloat(this.amount / 31).toFixed(0);
    },
  },
  methods: {
    keyUp() {
      // clear timeout variable
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.onChange();
      }, 800);
    },
    submitForm() {
      // let wrapper = this.$refs.form;
      let form = document.getElementsByTagName("form");
      form[0].submit();
    },
    chooseAmount(amount) {
      this.amount = amount;
      this.getForm();
    },
    onChange() {
      if (this.amount) {
        if (this.amount < 0) {
          this.amount = this.amount * -1;
        }
        if (this.amount == 0) {
          this.amount = 100;
        }
        this.getForm();
      }

      if (!this.amount) {
        this.amount = 100.0;
        this.getForm();
      }
    },
    async getForm() {
      this.isLoading = true;
      let amount = parseInt(this.amount).toFixed(2);
      amount = Number(amount).toFixed(2);
      let payload = {
        amount: amount,
        currency: "UAH",
      };
      let result = await this.$api
        .post("/donate/getForm/", payload)
        .then((response) => {
          this.isLoading = false;
          return response.data;
        })
        .catch((error) => {
          this.isLoading = false;
          return error;
        });
      this.form = result;
    },
  },
  mounted() {
    this.getForm();
    window.addEventListener("openDonateCard", () => {
      this.showModal = true;
    });
  },
};
</script>

<style lang="scss" scoped>
.donate {
  &__currency {
    padding-top: 6px;
    font-weight: 600;
    font-size: 16px;
    color: #666;
  }
  &__summary {
    color: #0281ca;
    display: flex;
    align-items: center;
    span {
      font-size: 32px;
      font-weight: bold;
      color: #000;
    }
    input {
      margin-right: 10px;
      height: 64px;
      text-align: center;
      font-size: 42px;
      width: 200px;
      appearance: none;
      border-radius: 8px;
      background: rgb(152, 243, 152);
      font-weight: bold;
      border: none !important;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
  .donate {
    height: 48px;
    font-size: 26px;
    line-height: 1;
    width: 320px;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  }
  &__option {
    display: inline-flex;
    cursor: pointer;
    background: #fff;
    border-radius: 6px;
    padding: 4px 8px;
    margin-right: 8px;
    margin-bottom: 10px;
    box-shadow: 0px 3px 10px 0px rgb(0 0 0 / 10%);
    color: #000;
    border: 3px solid transparent;
    transition: 0.3s;
    font-weight: 500;
    &.selected {
      background: #fab901;
      border-color: #fab901;
    }
  }
}
</style>
