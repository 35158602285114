// import { async } from "@firebase/util";

import { getFirestore } from "firebase/firestore";
import {
  collection,
  addDoc,
  getDocs,
  getDoc,
  query,
  where,
  doc,
  updateDoc,
  deleteDoc,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";

function firestoreMethods(app) {
  const db = getFirestore(app);
  return {
    add: async (collectionName, dataObject) => {
      var result;
      try {
        const docRef = await addDoc(collection(db, collectionName), dataObject);
        console.log("Document written with ID: ", docRef.id);
        result = {
          success: true,
          doc_id: docRef.id,
        };
      } catch (e) {
        console.error("Error adding document: ", e);
        result = {
          error: true,
          message: e,
        };
      }
      return result;
    },
    delete: async (collection, uid) => {
      let result;
      try {
        await deleteDoc(doc(db, collection, uid));
        result = { success: true };
      } catch (e) {
        result = {
          success: false,
          error: e,
        };
      }

      return result;
    },
    update: async (collection, uid, newData) => {
      const docRef = doc(db, collection, uid);
      let result;
      // Set the "capital" field of the city 'DC'
      try {
        await updateDoc(docRef, newData);
        result = { success: true };
      } catch (e) {
        result = {
          success: false,
          error: e,
        };
      }

      return result;
    },
    getCollection: async (inputArgs) => {
      var result = {};
      result["docs"] = [];
      let collectionRef = collection(db, inputArgs.collectionName);
      let args = [collectionRef];

      for (var key in inputArgs) {
        if (key.indexOf("where") > -1) {
          args.push(
            where(inputArgs[key][0], inputArgs[key][1], inputArgs[key][2])
          );
        }
      }
      if (inputArgs.order) {
        args.push(orderBy(inputArgs.order[0], inputArgs.order[1]));
      }
      if (inputArgs.limitBy) {
        args.push(limit(inputArgs.limitBy));
      }
      if (inputArgs.startAfter) {
        args.push(startAfter(inputArgs.startAfter));
      }
      let q = query(...args);
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        var item = doc.data();
        item["uid"] = doc.id;
        result.docs.push(item);
      });
      result["lastVisible"] = querySnapshot.docs[querySnapshot.docs.length - 1];
      return result;
    },

    getDocument: async (collectionName, uid) => {
      const docRef = doc(db, collectionName, uid);
      const docSnap = await getDoc(docRef);
      console.log(uid);
      var result;
      if (docSnap.exists()) {
        result = docSnap.data();
        result["uid"] = docSnap.id;
      } else {
        result = false;
      }
      return result;
    },
  };
}

export { firestoreMethods as default };
