<template>
  <div class="chat__time-messages my-5 py-2">
    <div class="line">
      <span>
        {{ formattedDate }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dateTime: {
      type: Number,
      required: true,
      default: () => {
        return new Date().toISOString();
      },
    },
  },
  computed: {
    formattedDate() {
      if (this.$moment(this.dateTime).isSame(new Date(), "day")) {
        let today = this.$moment(this.dateTime).calendar(null, {});
        today = today.split(" ")[0];
        return today;
      }
      return this.$moment(this.dateTime).format("DD MMMM YYYY");
    },
  },
};
</script>

<style lang="scss" scoped>
.chat__time-messages {
  .line {
    display: block;
    position: relative;
    width: 100%;
    border-bottom: 1px solid rgba(#000, 0.2);
    margin-left: auto;
    margin-right: auto;
    span {
      font-family: "Fixel Text SH";
      font-style: normal;
      font-variation-settings: "wght" 400;
      font-size: 12px;
      line-height: 1.2;
      color: rgba(#000, 0.7);
      position: absolute;
      padding: 4px 8px;
      background: #fff;
      top: 0;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
  }
}
</style>
