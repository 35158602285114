<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <donateSection />
      </div>
    </div>
  </div>
</template>

<script>
import donateSection from "@/components/donate-section.vue";
export default {
  components: { donateSection },
};
</script>

<style lang="scss" scoped></style>
