<template>
  <div class="home__hero">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-9">
          <div class="hero-badge text-md-center">
            <div class="d-inline-flex align-items-center initiative-block">
              ініціатива
              <div class="photo">
                <img src="@/assets/img/halyna_yanchenko.jpg" alt="" />
              </div>
              Галини Янченко
            </div>
          </div>
          <h1 class="hero-title text-md-center">
            Перший та найбільший в Україні сервіс пошуку безкоштовного житла для
            біженців
          </h1>
          <p class="hero-subtitle text-md-center">
            Cайт “Прихисток” був створений у перший день широкомасштабного
            вторгнення росії 24 лютого 2022 року за ініціативи Галини Янченко та
            команди волонтерів. За цей час цей проект допоміг знайти тимчасове
            житло для понад 1 млн українців, а понад 4 млн людей відвідали сайт
            “Прихисток”. І досі щодня цей сервіс допомагає українським біженцям
            знайти тимчасове житло, як і новий дім.
          </p>
          <div
            class="hero-controls d-flex flex-column flex-md-row justify-content-md-center"
          >
            <b-button
              :to="{ name: 'find' }"
              size="lg"
              class="btn-find mb-3 mb-md-0 me-md-3 px-4"
              variant="danger"
              ><b-icon-search class="me-2" />
              <span> Знайти житло</span></b-button
            >
            <b-button :to="{ name: 'create' }" size="lg" class="btn-offer px-4"
              ><b-icon-plus class="me-2" /><span>Надати житло</span></b-button
            >
          </div>
        </div>
      </div>
      <div class="hero-video">
        <iframe
          v-if="selectedVideo == 'en'"
          width="560"
          height="315"
          :src="video.en"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <iframe
          v-if="selectedVideo == 'uk'"
          width="560"
          height="315"
          :src="video.uk"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedVideo: "uk",
      video: {
        en: "https://www.youtube.com/embed/pQgJ72eEczk",
        uk: "https://www.youtube.com/embed/5PqJtey-Q_I",
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
  &__hero {
    .hero-badge {
      & > div {
        border: 1px solid rgba(#fff, 0.2);
        padding: 12px 24px;
        border-radius: 32px;
        background: rgba(#fff, 0.1);
        font-weight: 600;
        @media (max-width: 768px) {
          background: var(--yellow);
          color: #000;
        }
      }
      .photo {
        width: 36px;
        min-width: 36px;
        background-color: #fff;
        height: 36px;
        border-radius: 50%;
        background-size: cover;
        margin: 0 12px;
        background-size: cover;
        background-position: center;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      margin-bottom: 20px;
    }
    .hero-title {
      font-size: 56px;
      font-weight: 400;
      margin-bottom: 40px;
      line-height: 1.2;
      @media (max-width: 768px) {
        font-size: 28px;
      }
    }
    .hero-subtitle {
      margin-bottom: 40px;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
    }
    .hero-video {
      aspect-ratio: 16/9;
      background: #000;
      border-radius: 24px;
      margin-top: 72px;
      margin-bottom: -72px;
      position: relative;
      z-index: 15;
      box-shadow: 0px 38px 82px 0px rgba(#000, 0.2);
      iframe {
        border-radius: 24px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
