<template>
  <b-card>
    <div class="header-card">
      <div class="date-published d-flex">
        <span v-if="updatedAt" class="date-edited">
          Оновлено {{ $moment(updatedAt).fromNow() }}
          <img src="@/assets/img/dot.svg" alt="" />
        </span>
        Додано {{ $moment(createdAt).fromNow() }}
      </div>
      <b-button
        variant="light"
        style="aspect-ratio: 1/1; background: #fff; border: none"
        @click="startReporting"
      >
        <b-icon-flag style="color: CD0C0C; height: 16px"></b-icon-flag>
      </b-button>
    </div>

    <div class="map" :style="'background-image: url(' + mapImgSrc + ')'">
      <div class="box-shadow"></div>
      <b-button :href="onMapLink" target="_blank" variant="light"
        >Подивитись на мапі</b-button
      >
    </div>
    <div class="location">
      {{ offer.region }}
    </div>
    <div class="title">
      {{ locationTitle }}
    </div>
    <div class="details">
      <div class="detail">
        <icon-svg variant="persons" class="me-2" />
        {{ offer.persons }}
      </div>
      <div class="detail">
        <icon-svg variant="door" class="me-2" />
        {{ accomodations[0] }}
      </div>
      <div class="detail">
        <icon-svg variant="calendar" class="me-2" />
        {{ periods[0] }}
      </div>
    </div>
    <div class="description">
      {{ truncateText }}
      <div>
        <a href="#" @click.prevent="showMore = !showMore" v-if="showMoreButton">
          {{ showMoreText }}
        </a>
      </div>
    </div>
    <div class="tags">
      <div v-for="(p, i) in personTypes" :key="i" class="tag">
        <b-icon-check2 class="me-2"></b-icon-check2>
        {{ p }}
      </div>
    </div>
    <div class="line"></div>
    <div class="buttons flex-wrap">
      <b-button variant="primary" @click="startMessaging" class="me-2 mb-2"
        >Написати</b-button
      >
      <b-button
        :href="'tel:' + offer.phone"
        variant="outline-primary"
        v-if="showCall"
        class="me-2 mb-2"
      >
        <b-icon-telephone class="me-2" />
        Зателефонувати</b-button
      >
      <b-button @click="toggleFavourite" class="mb-2" variant="outline-primary">
        <b-spinner small v-if="isLoading"></b-spinner>
        <div class="d-inline-flex" v-if="!isSaved && !isLoading">
          <b-icon-star class="me-2"></b-icon-star>
          Зберегти
        </div>
        <div class="d-inline-flex" v-if="isSaved && !isLoading">
          <b-icon-star-fill
            class="me-2"
            style="color: #ffb600"
          ></b-icon-star-fill>
          Збережено
        </div>
      </b-button>
    </div>
  </b-card>
</template>

<script>
export default {
  props: {
    offer: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      showMore: false,
      isLoading: false,
    };
  },
  computed: {
    isUser() {
      return this.$store.getters["isUser"];
    },
    datasets() {
      return this.$store.getters["datasets"] || {};
    },
    showCall() {
      return (
        this.offer.phone && this.offer.phone.length > 0 && this.offer.show_phone
      );
    },
    locationTitle() {
      let location = "";
      if (this.offer.region) {
        location += this.offer.region;
      }
      if (this.offer.city) {
        location += ", " + this.offer.city;
      }

      return location;
    },
    showMoreText() {
      return this.showMore ? "Згорнути" : "Розгорнути";
    },
    showMoreButton() {
      return this.offer.notes.length > 280;
    },
    updatedAt() {
      let updated = null;
      let lastUpdated = null;

      // check firebase
      if (this.offer.updated_at && this.offer.updated_at._seconds) {
        lastUpdated = new Date(this.offer.updated_at._seconds * 1000);
      }

      //check strapi
      if (this.offer.updated_by_user_at) {
        lastUpdated = new Date(this.offer.updated_by_user_at);
      }

      if (lastUpdated && lastUpdated > this.createdAt) {
        updated = lastUpdated;
      }

      return updated;
    },
    createdAt() {
      let created = null;
      if (this.offer.created_at && this.offer.created_at._seconds) {
        created = new Date(this.offer.created_at._seconds * 1000);
      }

      if (this.offer.createdAt) {
        created = new Date(this.offer.createdAt);
      }

      return created;
    },
    truncateText() {
      const text = this.offer.notes;
      const limit = 280;
      if (text.length > limit && this.showMore === false) {
        return text.slice(0, limit) + "...";
      }

      return text;
    },
    accomodations() {
      let all = this.offer.accomodation_type;
      let accomodations = this.datasets.accomodation_types || [];
      all = all.map((a) => {
        let accomodation = accomodations.find((ac) => ac.value === a);
        return accomodation.label || "";
      });
      return all;
    },
    personTypes() {
      let all = this.offer.persons_type;
      let personTypes = this.datasets.persons_type || [];
      all = all.map((a) => {
        let personType = personTypes.find((ac) => ac.value === a);
        return personType.label || "";
      });
      return all;
    },
    periods() {
      let all = this.offer.period;
      let periods = this.datasets.periods || [];
      all = all.map((a) => {
        let period = periods.find((ac) => ac.value === a);
        return period.label || "";
      });
      return all;
    },
    onMapLink() {
      let link = null;
      const { location_lat, location_lng } = this.offer;
      if (location_lat && location_lng) {
        link = `https://www.google.com/maps/search/?api=1&query=${location_lat},${location_lng}`;
      }

      return link;
    },
    mapImgSrc() {
      let url = require("@/assets/img/fullmap.png");
      if (this.offer.location_lat && this.offer.location_lng) {
        const lat = this.offer.location_lat;
        const lng = this.offer.location_lng;

        // Construct the Google Maps static map URL with latitude and longitude
        url = `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=10&size=400x400&key=AIzaSyAfNssi3issHmfxP3Run4A7Uaearyag8zI`;
      }

      if (this.offer.map_image) {
        url = this.offer.map_image;
      }

      return url;
    },
    isSaved() {
      return this.$store.getters["favourites"].find(
        (f) => f.id === this.offer.id
      );
    },
  },
  methods: {
    async toggleFavourite() {
      if (!this.isUser) {
        const event = new Event("openAuth");
        window.dispatchEvent(event);
        return;
      }
      this.isLoading = true;
      try {
        await this.$store.dispatch("toggleFavourite", this.offer.id);
        this.$nextTick(() => {
          if (this.isSaved) {
            this.$toast.success("Оголошення збережено");
          } else {
            this.$toast.warning("Оголошення видалено зі збережених");
          }
          this.$emit("update");
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    startMessaging() {
      if (!this.isUser) {
        const event = new Event("openAuth");
        window.dispatchEvent(event);
        return;
      }
      const event = new CustomEvent("write-a-message", {
        detail: { house: this.offer },
      });
      window.dispatchEvent(event);
    },
    startReporting() {
      const event = new CustomEvent("show-report-house-modal", {
        detail: { house: this.offer },
      });
      window.dispatchEvent(event);
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  margin-bottom: 16px;
  padding: 20px 24px;
  .header-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
    .date-published {
      font-size: 14px;
      line-height: 1.2;
      font-weight: 400;
      display: flex;
      align-items: center;
      .date-edited {
        color: var(--primary-blue);
        display: flex;
        align-items: center;
        img {
          margin: 0px 6px;
        }
      }
      @media screen and (max-width: 767px) {
        align-items: unset;
        flex-direction: column;
        .edited {
          img {
            display: none;
          }
        }
      }
    }
  }
  .map {
    margin-bottom: 16px;
    border-radius: 12px;
    width: 100%;
    height: 144px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .box-shadow {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      background: rgb(254, 219, 24);
      background: -moz-linear-gradient(
        180deg,
        rgba(254, 219, 24, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      background: -webkit-linear-gradient(
        180deg,
        rgba(254, 219, 24, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      background: linear-gradient(
        180deg,
        rgba(254, 219, 24, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fedb18",endColorstr="#ffffff",GradientType=1);
    }
    a {
      z-index: 2;
      height: 40px;
      font-size: 14px;
      border: #dcdcdc 2px solid;
    }
  }
  .location {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 4px;
  }
  .title {
    font-size: 24px;
    margin-bottom: 16px;
  }
  .details {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
    .detail {
      display: flex;
      align-items: center;
      margin-right: 24px;
      font-size: 14px;
      line-height: 1.2;
      margin-bottom: 8px;
      img {
        margin-right: 5px;
      }
    }
  }
  .description {
    font-size: 16px;
    margin-bottom: 16px;
    a {
      color: #004bc1;
      font-size: 16px;
    }
  }
  .tags {
    display: flex;
    flex-wrap: wrap;
    .tag {
      padding: 6px 12px;
      background: #f7f7f7;
      border-radius: 40px;
      display: flex;
      align-items: center;
      margin-right: 8px;
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
  .line {
    width: 100%;
    height: 1px;
    background: #dcdcdc;
    margin: 16px 0;
  }
  .buttons {
    .btn {
      padding: 12px 20px;
      height: 42px;
      font-weight: 400;
      @media (max-width: 767px) {
        width: 100% !important;
        margin-bottom: 8px;
      }
    }
    @media screen and(max-width: 767px) {
      // display: flex;
      // justify-content: space-between;
      // // button {
      // //   width: 48%;
      // //   margin: 0 !important;
      // // }
    }
  }
}
</style>
