<template>
  <b-card style="height: 100%">
    <div
      class="d-flex h-100 align-items-center flex-column justify-content-center py-5 text-center"
    >
      <circle-icon :image="image" color="#F9F9F9" />
      <p class="text-center mb-2">
        <slot name="text"> </slot>
      </p>
      <div class="d-flex justify-content-center actions">
        <slot name="actions"> </slot>
      </div>
    </div>
  </b-card>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      default: "home-1.png",
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  font-size: 16px;
  font-weight: 600;
}
.actions {
  font-size: 14px;
}
</style>
