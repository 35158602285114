<template>
  <div class="user">
    <div class="d-flex">
      <div class="d-flex align-items-center">
        <template v-if="!isUser">
          <v-google-translate
            style="margin-left: 12px"
            ref="langSwitcher"
            :fetchBrowserLanguage="false"
            :defaultLanguageCode="'uk'"
            :languages="langs"
            @select="langChanged"
          />
          <a
            href="#"
            class="text ms-2 d-none d-lg-flex align-items-center header-link"
            @click.prevent="handleOpen"
          >
            Увійти
          </a>
        </template>

        <template v-if="isUser">
          <div class="d-none d-lg-flex">
            <b-button to="/my/offers" class="ms-2 user-btn">
              Мої оголошення
            </b-button>
            <b-button to="/my/messages" class="ms-2 user-btn">
              Повідомлення
              <span class="count desktop ms-1" v-if="unreadsCount > 0">
                {{ unreadsCount }}
              </span>
            </b-button>
            <b-button to="/create" class="ms-2 me-3" variant="light">
              <b-icon-plus />
              Оголошення
            </b-button>
          </div>
          <v-google-translate
            ref="langSwitcher"
            :fetchBrowserLanguage="false"
            :defaultLanguageCode="'uk'"
            :languages="langs"
            @select="langChanged"
          />
          <div class="d-none d-lg-flex ms-2">
            <toggleMenu>
              <template slot="toggle">
                <div class="user__toggle">
                  <div class="avatar">
                    {{ displayName[0] }}
                  </div>
                  <div class="name">
                    {{ displayName }}
                  </div>
                </div>
              </template>
              <template slot="list">
                <a href="/my/favourites" class="d-flex align-items-center">
                  Збережені оголошення
                </a>
                <a
                  v-if="isUser"
                  href="/my/subscriptions"
                  class="d-flex align-items-center"
                >
                  Підписки на оновлення
                </a>
                <a
                  v-if="isUser && !telegramAdded"
                  href="#"
                  class="add-telegram"
                  @click.prevent="addTelegram"
                >
                  <b-icon-telegram class="me-2" />
                  Підключити Telegram
                </a>
                <div class="separator"></div>
                <a href="/my/profile" class="d-flex align-items-center">
                  Аккаунт
                </a>
                <div class="separator"></div>
                <a
                  href="#"
                  @click.prevent="signOut"
                  class="text-danger d-flex align-items-center"
                >
                  <strong> Вийти </strong>
                </a>
              </template>
            </toggleMenu>
          </div>
        </template>

        <div class="header__mobile-user d-lg-none">
          <toggleMenu>
            <template slot="toggle">
              <b-button :variant="isUser ? 'light' : 'link'">
                <icon-svg
                  v-if="isUser"
                  :color="isUser ? '#000' : '#fff'"
                  :size="32"
                  variant="persons"
                  class="me-1"
                />
                <icon-svg
                  :color="isUser ? '#000' : '#fff'"
                  :size="32"
                  variant="menu"
                />
              </b-button>
            </template>
            <template slot="list">
              <a v-if="isUser" href="/my/messages">
                Мої повідомлення
                <span class="count ms-1" v-if="unreadsCount > 0">
                  {{ unreadsCount }}
                </span>
              </a>
              <a
                v-if="isUser"
                href="/my/offers"
                class="d-flex align-items-center"
              >
                Мої оголошення
              </a>
              <a
                v-if="isUser"
                href="/my/favourites"
                class="d-flex align-items-center"
              >
                Збережені оголошення
              </a>
              <a
                v-if="isUser"
                href="/my/subscriptions"
                class="d-flex align-items-center"
              >
                Підписки на оновлення
              </a>
              <div v-if="isUser" class="separator"></div>
              <a href="/find">
                <b-icon-search class="me-2" v-if="!isUser" />
                <strong>Знайти житло </strong></a
              >
              <a href="/create">
                <b-icon-plus class="me-2" v-if="!isUser" />
                Запропонувати житло
              </a>
              <div v-if="isUser" class="separator"></div>
              <a
                v-if="isUser && !telegramAdded"
                href="#"
                class="add-telegram"
                @click.prevent="addTelegram"
              >
                <b-icon-telegram class="me-2" />
                Підключити Telegram
              </a>
              <a
                v-if="isUser"
                href="/my/profile"
                class="d-flex align-items-center"
              >
                Аккаунт
              </a>
              <div class="separator"></div>
              <a
                v-if="isUser"
                href="#"
                @click.prevent="signOut"
                class="text-danger d-flex align-items-center"
              >
                <strong> Вийти </strong>
              </a>
              <a v-if="!isUser" href="#" @click.prevent="handleOpen">
                <strong> Увійти </strong>
              </a>
            </template>
          </toggleMenu>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import toggleMenu from "@/components/toggle-menu.vue";
import vGoogleTranslate from "@/components/translate/index.vue";
export default {
  components: {
    vGoogleTranslate,
    toggleMenu,
  },
  data() {
    return {
      userSidebar: false,
      langs: [
        {
          code: "uk",
          name: "українська",
          cname: "乌克兰语",
          ename: "Ukrainian",
        },
        {
          code: "ru",
          name: "Русский",
          cname: "俄语",
          ename: "Russian",
        },
        {
          code: "pl",
          name: "Polski",
          cname: "波兰语",
          ename: "Polish",
        },
        {
          code: "en",
          name: "English",
          cname: "英语",
          ename: "English",
        },
        {
          code: "cs",
          name: "čeština",
          cname: "捷克语",
          ename: "Czech",
        },
        {
          code: "et",
          name: "eesti keel",
          cname: "爱沙尼亚语",
          ename: "Estonian",
        },
        {
          code: "fr",
          name: "Français",
          cname: "法语",
          ename: "French",
        },
        {
          code: "de",
          name: "Deutsch",
          cname: "德语",
          ename: "German",
        },
        {
          code: "af",
          name: "Afrikaans",
          cname: "南非语",
          ename: "Afrikaans",
        },
        {
          code: "sq",
          name: "Gjuha shqipe",
          cname: "阿尔巴尼亚语",
          ename: "Albanian",
        },
        {
          code: "ar",
          name: "العربية",
          cname: "阿拉伯语",
          ename: "Arabic",
        },
        {
          code: "hy",
          name: "Հայերեն",
          cname: "亚美尼亚语",
          ename: "Armenian",
        },
        {
          code: "az",
          name: "Азәрбајҹан дили",
          cname: "阿塞拜疆语",
          ename: "Azerbaijani",
        },
        {
          code: "eu",
          name: "Euskara",
          cname: "巴斯克语",
          ename: "Basque",
        },
        {
          code: "be",
          name: "беларуская мова",
          cname: "白俄罗斯语",
          ename: "Belarusian",
        },
        {
          code: "bg",
          name: "български език",
          cname: "保加利亚语",
          ename: "Bulgarian",
        },
        {
          code: "ca",
          name: "Català",
          cname: "加泰罗尼亚语",
          ename: "Catalan",
        },
        {
          code: "zh-CN",
          name: "Chinese (Simplified)",
          cname: "中文 (简体)",
          ename: "Chinese (Simplified)",
        },
        {
          code: "zh-TW",
          name: "Chinese (Traditional)",
          cname: "中文 (繁体)",
          ename: "Chinese (Traditional)",
        },
        {
          code: "hr",
          name: "Српскохрватски језик",
          cname: "克罗地亚语",
          ename: "Croatian",
        },

        {
          code: "da",
          name: "Danmark",
          cname: "丹麦语",
          ename: "Danish",
        },
        {
          code: "nl",
          name: "Nederlands",
          cname: "荷兰语",
          ename: "Dutch",
        },

        {
          code: "tl",
          name: "Filipino",
          cname: "菲律宾语",
          ename: "Filipino",
        },
        {
          code: "fi",
          name: "Finnish",
          cname: "芬兰语",
          ename: "Finnish",
        },

        {
          code: "el",
          name: "Ελληνικά",
          cname: "希腊语",
          ename: "Greek",
        },
        {
          code: "hu",
          name: "magyar",
          cname: "匈牙利语",
          ename: "Hungarian",
        },
        {
          code: "id",
          name: "Indonesia",
          cname: "印度尼西亚语",
          ename: "Indonesian",
        },
        {
          code: "ga",
          name: "Irish",
          cname: "爱尔兰语",
          ename: "Irish",
        },
        {
          code: "it",
          name: "Italiano",
          cname: "意大利语",
          ename: "Italian",
        },
        {
          code: "ja",
          name: "にほんご",
          cname: "日语",
          ename: "Japanese",
        },
        {
          code: "ko",
          name: "한국어",
          cname: "韩语",
          ename: "Korean",
        },
        {
          code: "lt",
          name: "lietuvių kalba",
          cname: "立陶宛语",
          ename: "Lithuanian",
        },
        {
          code: "ms",
          name: "Malay",
          cname: "马来西亚语",
          ename: "Malay",
        },
        {
          code: "no",
          name: "norsk",
          cname: "挪威语",
          ename: "Norwegian",
        },

        {
          code: "pt",
          name: "Português",
          cname: "葡萄牙语",
          ename: "Portuguese",
        },
        {
          code: "ro",
          name: "limba română",
          cname: "罗马尼亚语",
          ename: "Romanian",
        },

        {
          code: "es",
          name: "Español",
          cname: "西班牙语",
          ename: "Spanish",
        },
        {
          code: "sv",
          name: "Swedish",
          cname: "瑞典语",
          ename: "Swedish",
        },
        {
          code: "th",
          name: "ภาษาไทย",
          cname: "泰语",
          ename: "Thai",
        },
        {
          code: "tr",
          name: "Turkish",
          cname: "土耳其语",
          ename: "Turkish",
        },
      ],
    };
  },
  computed: {
    unreadsCount() {
      let count = this.$store.getters.unreadsCount || 0;
      if (count > 9) {
        return "9+";
      }
      return count;
    },
    telegramAdded() {
      return this.$store.getters.telegramAdded;
    },
    user() {
      return this.$store.state.user;
    },
    isUser() {
      let value = false;
      if (this.user && this.user.id) {
        value = true;
      }
      return value;
    },
    displayName() {
      let name = this.user.email.split("@")[0];
      if (this.user.first_name && this.user.last_name) {
        name = `${this.user.first_name} ${this.user.last_name[0]}.`;
      }
      return name;
    },
  },
  methods: {
    addTelegram() {
      const event = new CustomEvent("openTelegramAddModal");
      window.dispatchEvent(event);
    },
    langChanged(lang) {
      const event = new CustomEvent("langChange", { detail: lang.code });
      window.dispatchEvent(event);
    },
    toggleDropdown() {
      this.$refs.dropdown.show(true);
    },
    async signOut() {
      var result = await this.$auth.logOut();
      if (result == true) {
        this.$toast.warning("Ви вийшли із кабінету");
      }
    },

    handleOpen() {
      if (this.user && this.user.uid) {
        this.userSidebar = !this.userSidebar;
      } else {
        this.openAuthModal();
      }
    },
    openAuthModal() {
      const event = new Event("openAuth");
      window.dispatchEvent(event);
    },
  },
};
</script>

<style lang="scss" scoped>
.registration {
  margin-left: 10px;
}
.login {
  min-width: 120px;
}
.user {
  .btn {
    outline: none !important;
    box-shadow: none !important;
  }
  .user-btn {
    background: none;
    border: none;
    font-size: 16px;
    color: rgba(#fff, 0.8);

    &:hover {
      background: rgba(255, 255, 255, 0.1);
      color: #fff;
    }
  }
  .btn-light {
    background: #fff !important;
    color: var(--primary-blue) !important;
    font-weight: 500;
    font-size: 16px;
  }
  &__toggle {
    display: flex;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
    &:hover {
      .name {
        background: rgba(255, 255, 255, 0.1);
        transition: 0.3s;
      }
    }
    .avatar {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background: var(--yellow);
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000;
      text-transform: uppercase;
    }
    .name {
      color: #fff;
      margin-right: 8px;
      font-size: 14px;
      font-weight: 400;
      display: inline-flex;
      height: 48px;
      align-items: center;
      padding: 0px 12px;
      border-radius: 32px;
      transition: 0.3s;
    }
  }
}
span.count {
  background: var(--primary-blue);
  color: #fff;
  width: 20px;
  height: 20px;
  min-width: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 12px;
  margin-left: 4px;
  aspect-ratio: 1;
  margin-left: 8px !important;
  font-weight: 600;
  &.desktop {
    background: #fff;
    color: var(--primary-blue);
  }
}
</style>
