<template>
  <div class="founders text-center text-md-start py-5">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="mb-4 pb-3">Засновники проєкту</h2>
        </div>
        <div class="col-6 col-lg-3" v-for="(f, i) in founders" :key="i">
          <a :href="f.link" target="_blank" class="founders__item">
            <div
              class="image"
              :style="
                'background-image: url(' +
                require(`@/assets/img/${f.image}`) +
                ')'
              "
            ></div>
            <div class="name">
              {{ f.name }}
            </div>
            <div class="role">{{ f.role }}</div>
          </a>
          <!-- item -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      founders: [
        {
          name: "Галина Янченко",
          link: "https://www.facebook.com/halyna.yanchenko",
          role: "Народна депутатка України, ініціатор проєкту",
          image: "founders-one.png",
        },
        {
          name: "Віктор Андрусів",
          link: "https://www.facebook.com/victor.andrusiv",
          role: "Військовослужбовець ЗСУ, засновник волонтерського руху «Добробат»",
          image: "founders-two.png",
        },
        {
          name: "Дмитро Прядко",
          link: "",
          role: "керівник з розвитку",
          image: "dmytro-pryadko.jpeg",
        },
        {
          name: "Роман Турчин",
          link: "https://www.facebook.com/turchyn.roman/",
          role: "Керівник з ІТ",
          image: "founders-four.png",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.founders {
  background: #fff;
  h2 {
    font-size: 32px;
    color: black;
  }
  &__item {
    text-decoration: none;
    margin-bottom: 30px;
    display: block;
    .image {
      width: 100%;
      aspect-ratio: 1;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      margin-bottom: 12px;
    }
    .name {
      font-size: 20px;
      font-weight: bold;
      line-height: 1.2;
      padding-bottom: 4px;
    }
    .role {
      color: #999;
      font-weight: 300;
      line-height: 1.2;
    }
    p {
      color: #000;
    }
    &:hover {
      p {
        color: #182a4c;
      }
    }
  }
}
</style>
