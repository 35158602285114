<template>
  <header class="header">
    <div class="govsites">
      <div class="container d-flex align-items-center justify-content-between">
        <img src="@/assets/img/gov-sites.svg" alt="" />
        <a href="https://www.kmu.gov.ua/" target="_blank">
          <img height="24" src="@/assets/img/kabmin.svg" alt="" />
        </a>
      </div>
    </div>
    <div class="topline text-center px-2 py-1 py-md-0">
      Росія оголосила Україні війну і підло обстрілює мирні міста
    </div>
    <div class="container">
      <div
        class="d-flex header__height align-items-center justify-content-between"
      >
        <div class="header__left d-flex align-items-center">
          <div class="header__logo me-4">
            <router-link class="d-block" :to="{ name: 'home' }">
              ПРИХИСТОК
            </router-link>
          </div>
          <router-link
            class="header-link d-none d-lg-inline-block"
            :to="{ name: 'find' }"
          >
            Знайти житло</router-link
          >
          <router-link
            class="d-none d-lg-inline-block header-link"
            :to="{ name: 'create' }"
            variant="success"
            v-if="!isUser"
          >
            Запропонувати житло</router-link
          >
        </div>
        <div class="header__right d-flex align-items-center">
          <headerUser style="margin-left: 20px" />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import headerUser from "./header-user.vue";

export default {
  components: {
    headerUser,
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    isUser() {
      let value = false;
      if (this.user && this.user.id) {
        value = true;
      }
      return value;
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss">
.header {
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  z-index: 20;
  background: var(--primary-blue);
  color: #fff;
  .topline {
    background: #1a5dc7;
    min-height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 14px;
  }
  .header-link {
    color: #fff;
    text-decoration: none;
    margin-right: 24px;
    opacity: 0.8;
    font-size: 16px;
    &:hover {
      opacity: 1;
    }
  }

  .govsites {
    background: #000;
    .container {
      height: 48px;
      @media (max-width: 575px) {
        padding: 0px;
      }

      a {
        transition: 0.3s;
        &:hover {
          opacity: 0.8;
          transition: 0.3s;
        }
      }
      img {
        height: 30px;
        @media (max-width: 768px) {
          height: 22px !important;
        }
      }
    }
  }
  // border-bottom: 1px solid #0ba5ff;
  &__donate-mobile {
    position: absolute;
    background: #e7e7e7;
    display: none;
    color: #000;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 0px 20px;
    padding-left: 12px;
    right: 8px;
  }
  &__logo {
    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: #000;
      font-weight: bold;
      font-size: 26px;
      line-height: 1.2;
      color: #fff;
      padding-right: 24px;
      &:hover {
        color: #fff !important;
      }
      @media (max-width: 768px) {
        font-size: 20px;
      }
      div {
        font-size: 12px;
        font-weight: 300;
        opacity: 0.8;
      }
    }
  }
  &__height {
    height: 72px;
  }
}
</style>
