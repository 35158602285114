import Vue from "vue";
import Vuex from "vuex";

import api from "@/providers/api";

import VuexPersistence from "vuex-persist";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  key: "prykhystok",
});

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    userData: null,
    role: "anonymous",
    tags: [],
    categories: [],
    favourites: [],
    datasets: {},
    unreadsCount: 0,
    error: false,
    showSetup: false,
  },
  getters: {
    unreadsCount: (state) => {
      return state.unreadsCount;
    },
    isPhoneVerified: (state) => {
      return state.user && state.user.phone ? true : false;
    },
    favourites: (state) => {
      return state.favourites;
    },
    role: (state) => {
      return state.role;
    },
    categories: (state) => {
      return state.categories;
    },
    tags: (state) => {
      return state.tags;
    },
    user: (state) => {
      return state.user;
    },
    isUser: (state) => {
      return state.user && state.user.id ? true : false;
    },
    userData: (state) => {
      return state.userData;
    },
    showSetup: (state) => {
      return state.showSetup;
    },
    datasets: (state) => {
      return state.datasets;
    },
    telegramAdded: (state) => {
      return state.user && state.user.telegram_id ? true : false;
    },
  },
  mutations: {
    SET_ROLE(state, role) {
      state.role = role;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setFavourites(state, payload) {
      state.favourites = payload;
    },
    setUserData(state, payload) {
      state.user = payload;
    },
    setUnreadsCount(state, payload) {
      state.unreadsCount = payload;
    },
    setError(state, payload) {
      state.error = payload;
    },
    setTags(state, payload) {
      state.tags = payload;
    },
    setCategories(state, payload) {
      state.categories = payload;
    },
    setDatasets(state, payload) {
      state.datasets = payload;
    },
    TOGGLE_SETUP(state, payload) {
      state.showSetup = payload;
    },
  },
  actions: {
    async fetchDatasets(context) {
      console.log("fetchDatasets");
      try {
        const response = await api.get("/datasets");
        context.commit("setDatasets", response.data);
      } catch (error) {
        console.log(error);
      }
    },
    async toggleFavourite(context, houseId) {
      let result = await api.post(`/houses/toggleFavourite/${houseId}`);
      if (result.data.success) {
        context.commit("setFavourites", result.data.favourites);
      }
      return result;
    },
    async getUnreadsCount(context) {
      let result = await api.get("/messages/getUnreadCount");
      if (!result.data.count) {
        result.data.unreadsCount = 0;
      }
      context.commit("setUnreadsCount", result.data.count);
    },
    async fetchUserData(context) {
      try {
        const response = await api.get("/users/me");
        context.commit("setUserData", response.data);
      } catch (error) {
        console.log(error);
      }
    },
    async updateUserData(context, payload) {
      try {
        const response = await api.post("/users/update", payload);
        context.commit("setUserData", response.data);
      } catch (error) {
        console.log(error);
      }
    },
    setRole(context, payload) {
      context.commit("SET_ROLE", payload);
    },
    toggleSetup(context, payload) {
      context.commit("TOGGLE_SETUP", payload);
    },
    openAuthModal() {
      const event = new Event("openAuth");
      // Dispatch the event.
      window.dispatchEvent(event);
    },
  },
  modules: {},
  plugins: [vuexLocal.plugin],
});
