<template>
  <div class="search-filter">
    <div class="d-flex">
      <h5 class="mb-4">Фільтр</h5>
      <a
        v-if="filtersSelected"
        href="#"
        @click.prevent="clearFilters"
        class="ms-auto clear-filters"
      >
        <small>
          <b-icon-x />
          Скинути фільтри</small
        ></a
      >
    </div>
    <div class="search-filter__group persons">
      <div class="group-title">Кількість осіб</div>
      <personsInput
        ref="personsInput"
        v-model="filters.persons"
        @update="setPersons"
        :key="key"
      />
    </div>
    <div class="search-filter__group">
      <div class="group-title">Для кого шукаєте</div>
      <label
        :for="'person_type_' + type.id + '_' + key"
        class="d-block filter-checkbox"
        v-for="type in personTypes"
        :key="type.id"
      >
        <input
          type="checkbox"
          name="person_type"
          :id="'person_type_' + type.id + '_' + key"
          v-model="filters.persons_type"
          :value="type.id"
        />
        <span class="fake-input">
          <b-icon-check />
        </span>
        {{ type.label }}
      </label>
    </div>
    <div class="search-filter__group">
      <div class="group-title">Тип розміщення</div>
      <label
        :for="'accomodation_' + a.id + '_' + key"
        class="d-block filter-checkbox"
        v-for="a in accommodationTypes"
        :key="a.id"
      >
        <input
          type="checkbox"
          name="person_type"
          :id="'accomodation_' + a.id + '_' + key"
          v-model="filters.accomodation_type"
          :value="a.id"
        />
        <span class="fake-input">
          <b-icon-check />
        </span>
        {{ a.label }}
      </label>
    </div>
    <div class="search-filter__group">
      <div class="group-title">Період розміщення</div>
      <label
        :for="'periods_' + p.id + '_' + key"
        class="d-block filter-checkbox"
        v-for="p in periods"
        :key="p.id"
      >
        <input
          type="checkbox"
          name="person_type"
          :id="'periods_' + p.id + '_' + key"
          v-model="filters.periods"
          :value="p.id"
        />
        <span class="fake-input">
          <b-icon-check />
        </span>
        {{ p.label }}
      </label>
    </div>
  </div>
</template>

<script>
import personsInput from "@/components/search/perons-count-input.vue";
export default {
  components: {
    personsInput,
  },
  data() {
    return {
      key: 1,
      filters: {
        persons: 1,
        persons_type: [],
        periods: [],
        accomodation_type: [],
      },
    };
  },
  watch: {
    filters: {
      handler() {
        this.emitFilters();
      },
      deep: true,
    },
  },
  computed: {
    datasets() {
      return this.$store.getters["datasets"] || {};
    },
    personTypes() {
      return this.datasets.persons_type || [];
    },
    periods() {
      return this.datasets.periods || [];
    },
    accommodationTypes() {
      return this.datasets.accomodation_types || [];
    },
    filtersSelected() {
      return (
        Object.keys(this.filters).some((key) => this.filters[key].length > 0) ||
        this.filters.persons > 1
      );
    },
  },
  methods: {
    typeIsChecked(id) {
      return this.filters.person_type.includes(id);
    },

    emitFilters() {
      this.$emit("filter", this.filters);
    },
    setPersons(value) {
      this.filters.persons = value;
    },
    clearFilters() {
      this.filters = {
        persons_type: [],
        periods: [],
        accomodation_type: [],
      };
      this.$set(this.filters, "persons", 1);
      this.key = Math.floor(Math.random() * 1000) + 1;
      this.emitFilters();
    },
    parseQuery() {
      const query = this.$route.query;
      if (query.persons) {
        this.filters.persons = parseInt(query.persons);
      }
      if (query.persons_type) {
        this.filters.persons_type = query.persons_type.split(",");
      }
      if (query.periods) {
        this.filters.periods = query.periods.split(",");
      }
      if (query.accomodation_type) {
        this.filters.accomodation_type = query.accomodation_type.split(",");
      }
    },
  },
  mounted() {
    this.key = Math.floor(Math.random() * 1000) + 1;
    this.parseQuery();
  },
};
</script>

<style lang="scss" scoped>
.search-filter {
  .clear-filters {
    color: var(--primary-blue);
    cursor: pointer;
    text-decoration: none;
    font-weight: 600;
  }
  &__group {
    margin-bottom: 24px;

    .group-title {
      font-weight: 500;
      margin-bottom: 12px;
      font-size: 16px;
    }
    .filter-checkbox {
      position: relative;
      padding-left: 30px;
      margin-bottom: 12px;
      font-size: 14px;
      cursor: pointer;
      input {
        display: none;
      }
      input:checked + .fake-input {
        background: var(--primary-blue);
        border-color: var(--primary-blue);
        color: #fff;
      }
      .fake-input {
        position: absolute;
        top: 0px;
        left: 0;
        width: 20px;
        height: 20px;
        border: 1px solid #dcdcdc;
        border-radius: 4px;
        background: #fff;
        color: transparent;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
