<template>
  <div>
    <b-overlay :show="isLoading" spinner-variant="primary" rounded="lg">
      <b-form @submit.prevent="signIn" class="login__form">
        <h3 class="pb-2">Вхід у кабінет</h3>
        <div class="input-group mb-2">
          <label class="d-block w-100">Email</label>
          <b-form-input v-model.trim="user.email"> </b-form-input>
        </div>
        <div class="input-group mb-2">
          <label class="d-block w-100">Пароль</label>
          <b-form-input type="password" v-model.trim="user.password">
          </b-form-input>
        </div>
        <hr />
        <div class="form-group w-100">
          <b-button type="submit" block size="lg" variant="primary w-100"
            >Увійти</b-button
          >
          <b-button
            block
            size="lg"
            variant="outline-primary  w-100 mt-2"
            @click.prevent="switchUsecase('registration')"
            >Реєстрація</b-button
          >
        </div>
        <div class="text-center pt-3">
          Забули пароль?
          <a href="#" @click.prevent="switchUsecase('forgot-password')"
            >Скидання паролю</a
          >
        </div>
      </b-form>
    </b-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      user: {
        email: "",
        password: "",
      },
      errorMsgs: {
        ["username-not-found"]: "Користувача з таким email не знайдено",
        ["wrong-password"]: "Невірний пароль",
        ["user-not-confirmed"]: "Користувач не підтверджений",
      },
    };
  },
  methods: {
    async signIn() {
      this.isLoading = true;
      var result = await this.$auth
        .login(this.user.email, this.user.password)
        .then((data) => {
          return data;
        })
        .catch((error) => {
          result = error;
        });
      console.log(result);
      this.isLoading = false;
      if (result.success == true) {
        this.$toast.success("Виконано вхід");
      }
      if (result.errors) {
        result.errors.forEach((err) => {
          const msg = this.errorMsgs[err] || err;
          this.$toast.error(msg);
        });
      }
    },
    switchUsecase(use_case) {
      this.$emit("switch", use_case);
    },
  },
};
</script>

<style lang="scss" scoped></style>
