<template>
  <div class="messages">
    <div class="container h-100">
      <div class="row flex-nowrap flex-lg-wrap h-100">
        <div
          class="messages__sidebar h-100"
          :class="{ hasActiveChat: hasActiveChat }"
        >
          <myMessagesChatList ref="chatsList" @select="setActiveChat" />
        </div>
        <div
          class="messages__chat p-1 h-100"
          :class="{ hasActiveChat: hasActiveChat }"
        >
          <div
            class="open_chat w-100 h-100 d-flex align-items-center justify-content-center"
            v-if="!active_chat.id"
          >
            Виберіть чат
          </div>
          <myMessagesChatStream
            v-else
            :chat="active_chat"
            :key="active_chat.id"
            class="h-100"
            @clearActiveChat="clearActiveChat"
            @openContacts="contactsOpened = true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myMessagesChatList from "@/components/my/messages/chat-list.vue";
import myMessagesChatStream from "@/components/my/messages/chat-stream.vue";
export default {
  components: {
    myMessagesChatList,
    myMessagesChatStream,
  },
  data() {
    return {
      active_chat: {},
      active_tab: "",
      contactsOpened: false,
      chats: [],
      current_chat_id: null,
      receiver: null,
    };
  },
  computed: {
    hasActiveChat() {
      return this.active_chat.id;
    },
  },
  methods: {
    clearActiveChat() {
      this.active_chat = {};
      this.$router.push({
        path: this.$route.path,
        query: {
          chat: null,
        },
      });
      this.$refs.chatsList.selected = {};
    },
    setActiveChat(chat) {
      this.active_chat = chat;
      this.$router.push({
        path: this.$route.path,
        query: {
          chat: chat.id,
        },
      });
      this.contactsOpened = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.messages {
  height: 60vh;
  min-height: 640px;
  .row {
    position: relative;
    overflow: hidden;
  }
  &__sidebar {
    transition: 0.3s;
    max-width: 400px;
    padding-left: 15px;
    z-index: 10;
    flex: 0 0 400px;
    @media screen and (max-width: 993px) {
      width: 100%;
      flex: 0 0 100%;
      max-width: 100%;
      transition: 0.3s;
      &.hasActiveChat {
        transform: translateX(-100%);
        transition: 0.3s;
      }
    }
  }
  &__chat {
    max-width: calc(100% - 400px);
    flex: 0 0 calc(100% - 400px);
    background: #fff;
    border-radius: 16px;
    @media screen and (max-width: 993px) {
      width: 100%;
      flex: 0 0 100%;
      max-width: 100%;
      transition: 0.3s;
      &.hasActiveChat {
        transform: translateX(-100%);
        transition: 0.3s;
      }
    }
  }
}
</style>
