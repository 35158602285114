<template>
  <div class="home__hero">
    <heroSection />
    <homeStats />
    <affiliateInfo />

    <div class="container">
      <div class="row">
        <div class="col-12">
          <donateSection />
        </div>
      </div>
    </div>
    <founders />
    <partners />
    <div class="faq">
      <faq />
    </div>
  </div>
</template>

<script>
import heroSection from "@/components/home-hero.vue";
import donateSection from "@/components/donate-section.vue";
import homeStats from "@/components/home-stats.vue";
import affiliateInfo from "@/components/affiliate-section.vue";
import partners from "@/components/footer/footer-partners.vue";
import founders from "@/components/footer/footer-founders.vue";

import faq from "@/views/faq-new/";
export default {
  data() {
    return {
      key: 1,
    };
  },
  components: {
    heroSection,
    homeStats,
    affiliateInfo,
    donateSection,
    partners,
    founders,
    faq,
  },
};
</script>

<style lang="scss" scoped>
.home {
  &__main-title {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 56px;
    line-height: 1;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      font-size: 56px;
    }
  }
  &__ride-banner {
    border: 1px solid #f00;
    padding: 20px;
    margin: 60px 0px;
  }
  &__controls {
    .btn {
      margin-bottom: 20px;
      @media (min-width: 600px) {
        margin-bottom: 15px;
      }
    }
  }
  &__hero {
    max-width: 100vw;
    padding: 30px 0px 0px;
    background-color: var(--primary-blue);
    background-size: cover;
    background-position: center;
    min-height: calc(60vh - 72px);
    box-sizing: border-box;
    position: relative;
    color: #fff;
    h2 {
      font-size: 24px;
    }
    iframe {
      max-width: 100%;
      background: #164193;
      height: 345px;
      @media (max-width: 400px) {
        height: 365px;
      }
      @media (max-width: 575px) {
        height: 60vw;
      }
    }
    .bg {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 1;
    }
    .container {
      position: relative;
      z-index: 10;
    }
  }
}
.faq {
  position: relative;
  z-index: 10;
}
.donate-hero-link {
  text-decoration: none;
  display: inline-block;
  margin: 12px;
  border: 1px dashed rgba(#244688, 0.4);
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 16px;
  background: #fee100;
  border: none;
  color: #000;
  font-weight: 600;
}
</style>
