<template>
  <div class="faq">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8">
          <h2 class="mb-4">Поширені запитання</h2>
          <div v-for="(q, i) in questions" :key="i" class="faq__accordeon">
            <div class="accordeon-header" @click="toggleAccordeon(i)">
              <h4 class="mb-0">
                {{ q.question }}
                <b-icon-plus
                  class="icon"
                  :class="{ expanded: accordeon[i].expanded == true }"
                />
              </h4>
            </div>
            <div
              class="accordeon-body"
              :class="{ expanded: accordeon[i].expanded == true }"
            >
              <div class="p-3" v-html="documentToHtmlString(q.answer)"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
export default {
  data() {
    return {
      documentToHtmlString,
      questions: [],
      accordeon: [],
    };
  },
  methods: {
    async getQuestions() {
      this.isLoading = true;
      this.questions = [];
      let args = {
        collectionName: "faq",
      };
      let result = await this.$db.getCollection(args);
      this.questions = result.docs;
      result.docs.forEach(() => {
        this.accordeon.push({ expanded: false });
      });
    },
    toggleAccordeon(i) {
      this.accordeon[i].expanded = !this.accordeon[i].expanded;
    },
  },
  mounted() {
    this.getQuestions();
  },
};
</script>

<style lang="scss">
.faq {
  background: #f7f7f7;
  margin: 0 0;
  padding: 30px 0px 60px;
  color: #000;
  h2 {
    font-size: 32px;
  }
  &__accordeon {
    border-bottom: 1px solid #e7e7e7;
    .accordeon-header {
      padding: 16px 0px;
      position: relative;
      padding-right: 42px;
      h4 {
        font-weight: 500;
        font-size: 20px;
      }
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        background: rgba(#666, 0.1);
        transition: 0.3s;
      }
      .icon {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        transition: 0.3s;
        font-size: 32px;
        font-weight: 300;
        &.expanded {
          transform: translateY(-50%) rotate(45deg);
          transition: 0.3s;
        }
      }
    }
    .accordeon-body {
      height: 0px;
      max-height: 0px;
      overflow: hidden;
      transition: 0.3s;
      font-size: 16px;
      &.expanded {
        height: auto;
        max-height: 1200px;
        transition: 0.3s;
        h4 {
          font-size: 18px !important;
        }
      }
    }
  }
}
</style>
