<template>
  <b-modal v-model="show" centered hide-header hide-footer>
    <h3>Надіслати скаргу</h3>
    <b-alert show variant="danger" class="p-2">
      <strong>
        {{ house.location_title }}
      </strong>
    </b-alert>

    <div class="reasons pt-4">
      <div class="mb-1" v-for="r in reasons" :key="r.id">
        <label>
          <input
            type="radio"
            :value="r.id"
            name="removal-reason"
            v-model="reason"
          />
          {{ r.label }}
        </label>
      </div>
    </div>
    <div class="controls pt-4 d-flex flex-column">
      <b-button
        :disabled="isLoading || !reason"
        variant="danger mb-2"
        @click="sendReport"
      >
        <b-spinner small v-if="isLoading" />
        <span v-else>Надіслати</span>
      </b-button>
      <b-button variant="outline-primary" @click="show = false" class="mr-2"
        >Скасувати</b-button
      >
    </div>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      show: false,
      reason: "",
      house: {},
    };
  },
  computed: {
    isUser() {
      return this.$store.getters["isUser"];
    },
    datasets() {
      return this.$store.state.datasets;
    },
    reasons() {
      let reasons = [];
      if (this.datasets && this.datasets.report_reasons) {
        reasons = this.datasets.report_reasons;
      }

      return reasons;
    },
  },
  methods: {
    async sendReport() {
      this.isLoading = true;
      await this.$api
        .post("/report/create/", {
          house: this.house.id,
          reason: this.reason,
        })
        .then((response) => {
          if (response.data.success) {
            this.$toast.success("Скарга надіслана");
            this.show = false;
          } else {
            this.$toast.warning("Помилка");
          }
        })
        .catch(() => {
          this.$toast.warning("Помилка");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    window.addEventListener("show-report-house-modal", (e) => {
      if (this.isUser) {
        this.house = e.detail.house;
        this.show = true;
      } else {
        const event = new CustomEvent("openAuth");
        window.dispatchEvent(event);
      }
    });
  },
};
</script>

<style lang="scss" scoped></style>
