<template>
  <div class="messages-chat h-100 d-grid">
    <div class="messages-chat__header">
      <chatHeader
        :chat="chat"
        :contact="contact"
        @clearActiveChat="clearActiveChat"
      />
    </div>
    <div class="messages-chat__stream d-flex flex-column">
      <div
        ref="chatRoll"
        v-chat-scroll="{
          always: false,
          smooth: true,
          notSmoothOnInit: true,
          scrollonremoved: true,
          enabled: enabledScroll,
        }"
        class="chat flex-wrap pb-3 px-4 h-100"
        @v-chat-scroll-top-reached="fetchOlderMessages"
      >
        <div
          class="pt-3 mt-3 mb-1"
          style="opacity: 0"
          :class="{ 'opacity-100': isLoading || loadingOlder }"
        >
          <b-overlay
            v-if="initLoad"
            :show="true"
            variant="white"
            spinner-variant="primary"
          />
          <b-overlay
            v-if="!initLoad"
            :show="true"
            variant="white"
            spinner-small
            spinner-variant="primary"
          />
        </div>
        <div v-for="m in orderedMessages" :key="m.id" class="w-100">
          <MyMessagesChatTime v-if="m.is_date" :date-time="m.createdAt" />
          <myMessagesChatProposal
            v-if="m.is_proposal"
            :message="m"
            :proposal="m.proposal"
          />
          <myMessagesChatMessage
            v-if="!m.is_offer && !m.is_proposal && !m.is_date"
            :message="m"
            @resendMessage="resendMessage"
          />
          <myMessagesChatOffer
            v-if="m.is_offer"
            :message="m"
            :offer="m.offer"
          />
        </div>
      </div>
    </div>
    <b-form class="messages-chat__form">
      <div class="form py-2 position-relative">
        <div class="d-flex align-items-center">
          <div class="form-input flex-4 form-input d-flex align-items-center">
            <textarea
              v-model="messageData.text"
              class="grey me-2"
              placeholder="Написати повідомлення"
              maxlength="600"
              rows="1"
              ref="messageInput"
              @keydown.enter.shift.exact.prevent="messageData.text += '\n'"
              @keyup.enter.exact.prevent="messageData.text += '\n'"
              @keyup.enter.ctrl.exact.prevent="sendMessage"
            >
            </textarea>
            <a
              href="#"
              :class="{ active: messageData.text.length > 0 }"
              class="send-cta"
              @click.prevent="sendMessage"
            >
              <icon-svg variant="send" />
            </a>
          </div>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import chatHeader from "@/components/my/messages/chat-header.vue";
import myMessagesChatMessage from "@/components/my/messages/chat-message.vue";
import MyMessagesChatTime from "@/components/my/messages/chat-time.vue";
export default {
  components: {
    chatHeader,
    myMessagesChatMessage,
    MyMessagesChatTime,
  },
  props: {
    chat: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      isLoading: false,
      enabledScroll: true,
      initLoad: true,
      loadingMore: false,
      loadingOlder: false,
      lastPage: false,
      perPage: 8,
      messages: [],
      queue: {},
      messageData: {
        status: "read",
        text: "",
        chat: null,
        sender: null,
        receiver: null,
      },
    };
  },
  computed: {
    containsContactData() {
      let containsPhone = false;
      let containsEmail = false;
      const phoneRegexp =
        /(\+38|8|38)?\s?\(?0\d{2}\)?\s?\d{3}\s?\d{2}\s?\d{2}/g;
      const emailRegexp = /[\w-.]+@([\w-]+\.)+[\w-]{2,4}/g;
      if (
        this.messageData.text.match(phoneRegexp) &&
        this.messageData.text.match(phoneRegexp).length > 0
      ) {
        containsPhone = true;
      }
      if (
        this.messageData.text.match(emailRegexp) &&
        this.messageData.text.match(emailRegexp).length > 0
      ) {
        containsEmail = true;
      }
      return containsPhone || containsEmail;
    },
    contact() {
      let contact = {};
      this.chat.participants.forEach((c) => {
        if (c.id !== this.$store.getters["user"].id) {
          contact = c;
        }
      });
      return contact;
    },
    receiverId() {
      return this.contact.id;
    },
    job() {
      let job = {};
      if (this.chat.job && this.chat.job) {
        job = this.chat.job;
      }
      return job;
    },
    orderedMessages() {
      let messages = JSON.parse(JSON.stringify(this.messages));
      let uniqueDates = [];
      messages.forEach((m) => {
        let date = new Date(m.createdAt);
        date = date.setHours(0, 0, 0, 0);
        if (!uniqueDates.includes(date)) {
          uniqueDates.push(date);
        }
      });

      uniqueDates = uniqueDates.map((d) => {
        return {
          is_date: true,
          createdAt: d,
        };
      });

      messages = [...uniqueDates, ...messages];

      messages.sort((a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt);
      });

      return messages;
    },
  },
  watch: {
    chat: {
      deep: true,
      handler() {
        this.fetchMessagesByChatId();
      },
    },
    messageData: {
      deep: true,
      handler() {
        const text = this.messageData.text;
        const textarea = this.$refs.messageInput;
        if (text.length > 0) {
          textarea.style.height = "auto";
          textarea.style.height = textarea.scrollHeight + "px";
        } else {
          textarea.style.height = "auto";
        }
      },
    },
  },
  mounted() {
    this.messageData.chat = this.chat.id;
    this.fetchMessagesByChatId();
  },
  methods: {
    clearActiveChat() {
      this.$router.push({ query: {} });
      this.$emit("clearActiveChat");
    },

    resendMessage(uid) {
      this.sendMessageToDb(uid);
    },
    async sendMessageToDb(uid) {
      const message = JSON.parse(JSON.stringify(this.queue[uid]));
      delete message.local_status;
      delete message.uid;
      delete message.createdAt;
      if (
        message.text.toLowerCase().split("\n").join("").split(" ").join("")
          .length > 0
      ) {
        const target = this.messages.findIndex((m) => m.uid === uid);
        this.messages[target].local_status = "sending";
        await this.$api
          .post("/chats/sendMessage/" + this.chat.id, {
            data: message,
          })
          .then((response) => {
            const target = this.messages.findIndex((m) => m.uid === uid);
            this.messages[target].local_status = "sent";
            this.messages[target].id = response.data.id;
            // this.fetchMessagesByChatId();
          })
          .catch((err) => {
            console.log(err);
            const target = this.messages.findIndex((m) => m.uid === uid);
            this.messages[target].local_status = "error";
          });
      }
    },
    sendMessage() {
      if (
        this.messageData.text
          .toLowerCase()
          .toLowerCase()
          .split("\n")
          .join("")
          .split(" ")
          .join("").length > 0
      ) {
        const uid = Math.random().toString(12).substring(7);
        const message = {
          uid: uid,
          chat: this.chat.id,
          sender: this.$store.getters["user"].id,
          receiver: this.receiverId,
          text: this.messageData.text,
          createdAt: new Date(),
          status: "read",
          local_status: "sending",
        };
        this.messages.push(message);
        this.messageData.text = "";
        this.queue[uid] = message;
        this.sendMessageToDb(uid);
      }
    },
    async fetchOlderMessages() {
      if (this.initLoad || this.lastPage) return;

      const height = this.$refs.chatRoll.scrollHeight;
      const scroll = this.$refs.chatRoll.scrollTop;

      this.loadingOlder = true;
      // get oldest message
      const oldestMessage = this.messages[0];
      await this.$api
        .post(`/chats/getMessages/${this.chat.id}`, {
          before: oldestMessage.createdAt,
          perPage: this.perPage,
        })
        .then((response) => {
          // check new messages
          const olderMessages = response.data.data.filter((msg) => {
            return !this.messages.find((m) => m.id === msg.id);
          });
          const messages = olderMessages.map((el) => {
            return {
              id: el.id,
              sender: el.sender.id,
              receiver: el.receiver.id,
              text: el.text,
              createdAt: el.createdAt,
              is_seen: el.is_seen,
              is_offer: el.is_offer,
              is_proposal: el.is_proposal,
              offer: el.offer,
              proposal: el.proposal,
            };
          });
          if (response.data.data.length < this.perPage) {
            this.lastPage = true;
          }
          messages.forEach((m) => {
            this.messages.unshift(m);
          });

          this.$nextTick(() => {
            this.$refs.chatRoll.scrollTop =
              this.$refs.chatRoll.scrollHeight - height + scroll;
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loadingOlder = false;
        });
    },
    async fetchMessagesByChatId() {
      if (this.initLoad) {
        this.isLoading = true;
      } else {
        this.loadingMore = true;
      }
      await this.$api
        .post(`/chats/getMessages/${this.chat.id}`, {
          perPage: this.perPage,
        })
        .then((response) => {
          // check new messages
          const newMessages = response.data.data.filter((msg) => {
            return !this.messages.find((m) => m.id === msg.id);
          });
          const messages = newMessages.map((el) => {
            return {
              id: el.id,
              sender: el.sender.id,
              receiver: el.receiver.id,
              text: el.text,
              createdAt: el.createdAt,
              is_seen: el.is_seen,
              is_offer: el.is_offer,
              is_proposal: el.is_proposal,
              offer: el.offer,
              proposal: el.proposal,
            };
          });

          messages.forEach((m) => {
            this.messages.unshift(m);
          });
          this.initLoad = false;

          // check messages status
          const messagesToUpdate = this.messages.filter((msg) => {
            return (
              msg.local_status === "sent" &&
              response.data.data.find((m) => m.id === msg.id).is_seen
            );
          });
          messagesToUpdate.forEach((m) => {
            const target = this.messages.findIndex((msg) => msg.id === m.id);
            this.messages[target].local_status = "seen";
            this.messages[target].is_seen = true;
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
          this.loadingMore = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.messages-chat {
  display: grid;
  grid-template-rows: auto minmax(0, 2fr) auto;
  grid-template-columns: 1fr;
  max-width: 100%;

  &__stream {
    background: #fff;

    .opacity-100 {
      opacity: 1 !important;
    }
    .chat {
      overflow-x: hidden;
      overflow-y: scroll;
      max-width: calc(100% - 30px);
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }
  }
  &__form {
    margin-bottom: 10px;
    .send-cta {
      margin-right: 4px;
      svg {
        fill: #dcdcdc !important;
        width: 24px;
        height: 24px;
      }
      &.active {
        svg {
          fill: var(--primary-color) !important;
        }
      }
    }
    .form-input {
      flex-grow: 8;
      border: 1px solid #dcdcdc;
      padding: 12px 8px;
      border-radius: 8px;
      transition: 0.3s;
      &:focus-within {
        border-color: var(--primary-color);
        transition: 0.3s;
      }
    }

    textarea {
      padding: 0;
      resize: none !important;
      max-height: 100px;
      transition: 0.3s;
      background: #fff !important;
      border-radius: 6px !important;
      border: none !important;
      width: 100% !important;
      &:focus {
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
      }
    }
    .form {
      max-width: calc(100% - 30px);
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }
  }
  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    z-index: 99;
    &.show {
      opacity: 1;
      visibility: visible;
    }
  }
  &__work-info {
    position: fixed;
    width: 600px;
    max-width: 90%;
    right: 0;
    top: 0;
    bottom: 0;
    background: #fff;
    z-index: 9999;
    transition: 0.3s;
    transform: translateX(100%);
    &.show {
      transform: translateX(0%);
      box-shadow: 0px 0px 30px 0px rgba(#000, 0.2);
      transition: 0.3s;
    }
  }

  &__people {
    .name {
      font-family: "Fixel Text SH";
      font-style: normal;
      font-variation-settings: "wght" 500;
      font-size: 16px;
      line-height: 1.2;
      color: #000000;
    }
    .status {
      font-family: "Fixel Text SH";
      font-style: normal;
      font-variation-settings: "wght" 400;
      font-size: 14px;
      line-height: 1.2;
      color: #000000;
      opacity: 0.6;
    }
  }
}
</style>
