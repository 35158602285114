<template>
  <div class="location-input">
    <input
      :id="'searchTextField-' + key"
      :ref="'searchTextField-' + key"
      v-model="locationData.location_title"
      class="w-100"
      type="text"
      :class="{ 'is-invalid': error }"
      itemprop="query-input"
      :placeholder="placeholder"
      @keyup="() => {}"
    />
    <div v-if="loadingLocation" class="loader">
      <b-spinner></b-spinner>
    </div>

    <a class="locate-btn d-none" @click.prevent="geolocateUser">
      <b-icon-geo-alt />
    </a>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    error: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      key: 1,
      locationData: {
        location_title: "",
        location_lat: "",
        location_lng: "",
      },
      place: {},
      errorLocation: false,
      errorLocationDescp: "",
      loadingLocation: false,
      last_selected_title: "",
    };
  },
  watch: {
    locationData: {
      handler(oldVal, newVal) {
        if (
          this.last_selected_title.length &&
          newVal.location_title !== this.last_selected_title
        ) {
          this.resetLocation();
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.title) {
      this.locationData.location_title = this.title;
    }
    this.$nextTick(() => {
      setTimeout(() => {
        this.autocompleteLocate();
        this.checkQueryForLocation();
      }, 1000);
    });
  },
  beforeMount() {
    this.key = Math.random().toString(36).substring(3);
  },
  methods: {
    resetLocation() {
      this.locationData.location_lat = "";
      this.locationData.location_lng = "";
      this.locationData.location_title = "";
      this.last_selected_title = "";
      this.$emit("setLocation", this.locationData);
    },
    autocompleteLocate() {
      const defaultBounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(-33.8902, 151.1759),
        new window.google.maps.LatLng(-33.8474, 151.2631)
      );
      const input = this.$refs["searchTextField-" + this.key];
      const options = {
        bounds: defaultBounds,
        types: [
          "neighborhood",
          "locality",
          "sublocality",
          "sublocality_level_1",
          "postal_code",
        ],
      };

      this.autocomplete = new window.google.maps.places.Autocomplete(
        input,
        options
      );

      this.autocomplete.addListener("place_changed", () => {
        this.loadingLocation = true;
        try {
          const place = this.autocomplete.getPlace();
          this.place = place;
          let title = place.formatted_address;

          const lastPart = title.split(",").pop().trim();
          if (lastPart.match(/^\d+$/)) {
            title = title.replace(", " + lastPart, "").trim();
          }
          this.locationData.location_title = title;
          this.last_selected_title = title;

          // this.locationData.location_title = place.formatted_address;
          this.locationData.location_lat = place.geometry.location.lat();
          this.locationData.location_lng = place.geometry.location.lng();
        } catch (e) {
          console.log(e);
        }
        this.loadingLocation = false;

        this.$emit("setLocation", { ...this.locationData, place: this.place });
      });
    },
    async getAdressFrom(lat, lng) {
      this.loadingLocation = true;
      const googleApiKey = "AIzaSyAfNssi3issHmfxP3Run4A7Uaearyag8zI";
      await axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${googleApiKey}`
        )
        .then((response) => {
          if (response.data.error_message) {
            console.log(response.data.error_message);
          } else {
            let locality = response.data.results.find(
              (item) =>
                item.types.includes("sublocality") ||
                item.types.includes("locality")
            );
            if (this.$route.query.no_sublocality) {
              locality = response.data.results.find((item) =>
                item.types.includes("locality")
              );
            }

            let title = locality.formatted_address;

            const lastPart = title.split(",").pop().trim();
            if (lastPart.match(/^\d+$/)) {
              title = title.replace(", " + lastPart, "").trim();
            }
            this.locationData.location_title = title;
            this.locationData.location_lat = lat;
            this.locationData.location_lng = lng;
            this.$emit("setLocation", this.locationData);
          }
        })
        .catch((e) => {
          console.log(e.message);
        });
      this.loadingLocation = false;
    },
    geolocateUser() {
      if (navigator.geolocation) {
        this.loadingLocation = true;

        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.errorLocation = false;
            this.getAdressFrom(
              position.coords.latitude,
              position.coords.longitude
            );
            this.loadingLocation = false;
          },
          (error) => {
            this.errorLocation = true;
            this.errorLocationDescp = error.message;
            console.log(error.message);
            this.loadingLocation = false;
          }
        );
      } else {
        console.log("Браузер не підтримує геолокацію");
      }
    },
    async checkQueryForLocation() {
      console.log("checking for location");
      if (this.$route.query.location) {
        const location = this.$route.query.location.split(",");
        if (location) {
          console.log(location);
          await this.getAdressFrom(location[1], location[0]);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.location-input {
  position: relative;
  height: 100%;
  input {
    height: 100%;
    width: 100%;
    border: none;
    padding-right: 60px;
    padding-left: 6px;
    outline: none !important;
    background: none;
    background: transparent !important;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }
  .loader {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
  }
  .locate-btn {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 40px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
</style>
