<template>
  <div class="my-messages py-4 h-100">
    <div class="container h-100">
      <div class="row h-100" v-if="noMessages && !isLoading">
        <div class="col-12 h-100">
          <no-data image="messages.png">
            <template #text> Повідомлень поки немає </template>
            <template #actions>
              <div>
                Як тільки ви отримаєте повідомлення, воно з'явиться тут. <br />
                Якщо ви хочете прихистити біженців, почніть з
                <a href="/create"> публікації оголошення</a>
              </div>
            </template>
          </no-data>
        </div>
      </div>
      <chatMainView v-if="!noMessages && !isLoading" />
    </div>
  </div>
</template>

<script>
import chatMainView from "@/components/my/messages/chat-main.vue";
export default {
  components: {
    chatMainView,
  },
  data() {
    return {
      isLoading: false,
      dataLoaded: false,
      chats: [],
    };
  },
  computed: {
    noMessages() {
      return this.dataLoaded && !this.chats.length;
    },
  },
  methods: {
    async fetchChatList() {
      this.isLoading = true;
      await this.$api
        .post(`/chats/getUserChats`)
        .then((response) => {
          // console.log(response.data);
          this.chats = response.data.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
          this.dataLoaded = true;
        });
    },
  },
  mounted() {
    this.fetchChatList();
  },
};
</script>

<style lang="scss" scoped></style>
