<template>
  <div>
    <div class="container">
      <div class="row justify-content-center py-5 my-4">
        <div class="col-12 col-sm-5">
          <b-card>
            <registerForm @switch="switchUsecase" />
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import registerForm from "@/components/auth/register-form.vue";
export default {
  components: {
    registerForm,
  },
  methods: {
    switchUsecase(use_case) {
      console.log(use_case);
      this.$router.push("/" + use_case);
    },
  },
};
</script>

<style lang="scss" scoped></style>
