<template>
  <div class="pt-5">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1>Перегляд оголошення</h1>
        </div>
        <div class="col-12 pt-3 pb-5">
          <skeletonHouseCard v-if="isLoading" />
          <HouseCardNew
            v-if="!isLoading && dataLoaded && offer.id"
            :offer="offer"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import skeletonHouseCard from "@/components/skeleton/house-card.vue";
import HouseCardNew from "@/components/house-card-new.vue";
export default {
  components: {
    skeletonHouseCard,
    HouseCardNew,
  },
  data() {
    return {
      offer: {},
      isLoading: false,
      dataLoaded: false,
    };
  },
  methods: {
    async fetchOffer() {
      this.isLoading = true;
      await this.$api
        .get("/house/get/" + this.$route.params.id)
        .then((response) => {
          this.offer = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.dataLoaded = true;
        });
    },
  },
  mounted() {
    this.fetchOffer();
  },
};
</script>

<style lang="scss" scoped></style>
