import { render, staticRenderFns } from "./cvk-address-input.vue?vue&type=template&id=6352fbba&scoped=true&"
import script from "./cvk-address-input.vue?vue&type=script&lang=js&"
export * from "./cvk-address-input.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6352fbba",
  null
  
)

export default component.exports