<template>
  <b-modal v-model="showModal" hide-header hide-footer>
    <b-overlay :show="isLoading" spinner-variant="primary" rounded="lg">
      <div class="login__form" v-if="use_case == 'login'">
        <loginForm @switch="switchUsecase" />
      </div>
      <div v-if="use_case == 'forgot-password'">
        <forgotPassword @switch="switchUsecase" />
      </div>
      <div v-if="use_case == 'registration'">
        <registerForm @switch="switchUsecase" />
      </div>
      <div class="login__password-sent" v-if="use_case == 'email_sent'">
        <h5 class="text-center">
          <div class="mb-4 mt-2 circle-icon"><b-icon-envelope-fill /></div>
          Листа з подальшими інструкціями надіслано на Вашу електронну адресу
          <br />
          <strong>
            {{ user.email }}
          </strong>
        </h5>
        <div class="pt-2">
          <b-button
            block
            size="lg"
            variant="primary w-100"
            @click="use_case = 'login'"
          >
            Увійти
          </b-button>
        </div>
      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
import loginForm from "@/components/auth/login-form.vue";
import registerForm from "@/components/auth/register-form.vue";
import forgotPassword from "@/components/auth/forgot-password.vue";
export default {
  components: {
    loginForm,
    registerForm,
    forgotPassword,
  },
  props: ["show"],
  data() {
    return {
      use_case: "login",
      showModal: false,
      isLoading: false,
    };
  },
  watch: {
    userData: {
      handler() {
        if (this.userData) {
          this.showModal = false;
        }
      },
    },
    showModal: {
      handler(val) {
        if (!val) {
          setTimeout(() => {
            this.use_case = "login";
          }, 600);
        }
      },
    },
  },
  computed: {
    userData() {
      return this.$store.state.user;
    },
  },
  methods: {
    switchUsecase(use_case) {
      this.use_case = use_case;
    },
  },
  mounted() {
    window.addEventListener("openAuth", () => {
      this.showModal = true;
    });
  },
};
</script>

<style lang="scss" scoped></style>
